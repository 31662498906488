import { NumericFormat } from 'react-number-format';
import Tabla from '../componentes/Tabla';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faDollar, faDollarSign, faEye, faFilePen, faPencil, faTrashAlt, faX } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import UserContext from '../../context/UserContext';
import { SUPERADMIN, TAQUILLA, VISITANTE } from '../../constantes/roles';
import { ApiPaths, KeysNuvei } from '../../utils';
import useTraeDatos from "../hooks/useTraeDatos";
import * as RUTAS from '../../constantes/rutas';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { AlertaConfirmacion } from '../componentes/AlertaConfirmacion';
import { toast } from 'react-toastify';

const baseUrlGeneral = window.location.origin;
const API_LOGIN_DEV = KeysNuvei.API_LOGIN_DEV;
const API_KEY_DEV = KeysNuvei.API_KEY_DEV;
const API_URL_DEV = KeysNuvei.API_URL_DEV;

const VerReservas = ({ reservas, seleccionaRegistro, exportarCSV }) => {
	// Obtener el contexto del usuario
	const { user } = useContext(UserContext);
	// Definir estados locales
	const [mostrarAlerta, setMostrarAlerta] = useState(false);
	const [alertaTexto, setAlertaTexto] = useState('')
	const [datosCupos, setDatosCupos] = useState({SumatotalHospedajes: 0, HospedajeReservaOriginal: 0, capacidadHospedaje: 0, SumatotalCupos: 0, CuposcompletosOriginal: 0, CapacidadSenderoDia1: 0, CuposcompletosNuevo: 0, hospedajesTotalesEditado: 0, multipleDias:false, ArraydeDiasCuposMultiple: [], TieneCapacidad: false})
	const [saldosPagar, setSaldosPagar] = useState()
	const [parametrosPago, setParametrosPago] = useState({})
	const [pagarconSaldo, setPagarconSaldo] = useState(false)
	const [servicios] = useTraeDatos(ApiPaths.servicios + "?tipo_ingreso=" + (3)); //para que traiga los hospedajes
	const [tiposingresos] = useTraeDatos(ApiPaths.ingresos)

	// Obtener datos de abonos
	const [abonos] = useTraeDatos(ApiPaths.operadoresAbonos, { idOperador: user?.operadorId })

	// Función para determinar el estado de la reserva
	const Estados = (dato) => {
		if (dato === 'PENDIENTE') {
			return 'P'
		} else if (dato === 'APROBADO') {
			return 'A'
		} else if (dato === 'RECHAZADO') {
			return 'R'
		} else if (dato === 'CANCELADO') {
			return 'C'
		} else if (dato === 'COMPLETADO') {
			return 'C'
		}
	}

	// Definir las columnas de la tabla
	const columns = [
		{
			title: 'Estado',
			render: (item) => <span className={`estados ${item.estado}`}>{Estados(item.estado)}</span>,
			width: "3%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "estado"
		},
		{
			title: 'Cod', render: (item) => `${item.codigoReserva}`,
			width: "10%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "codigoReserva"
		},
		{
			title: "Creación", field: "fechaReserva", width: "17%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Ingreso", field: "tipoingreso", width: "30%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Fecha Reserva", render: (item) => <>{item.fechaReservaInicial} {item.fechaReservaFinal && `- ${item.fechaReservaFinal}`}</>,
			width: "22%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "fechaReservaInicial"
		},
		{
			title: "Total", render: (item) => <NumericFormat displayType="text" value={item.total} prefix={'$'} thousandSeparator={true} />, width: "3%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "total"

		},
		{
			title: "Fecha Cancelación", field: "fechaReservaCancelacion", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Saldo", render: (item) => {
				if (item.saldos.trim() !== '') {
					const saldos = JSON.parse(decodeURI(item?.saldos))
					return (<NumericFormat displayType="text" value={saldos.saldoReserva} prefix={'$'} thousandSeparator={true} />)
				}
			}, width: "3%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "total"

		},
		{
			title: "Operador", field: "operador", width: "15%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Metodo Pago",
			field: "transaccionesMetodoPago",
			width: "15%",
			cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			hidden: parseInt(user.roll) != TAQUILLA,
		}
	];

	// Función para calcular la diferencia de días
	const diferenciaDias = (fecha, fechaInicial, saldos) => {
		let saldosTotal = saldos.trim() !== '' ? JSON.parse(decodeURI(saldos)) : ''

		if (parseInt(user.roll) === TAQUILLA) return false;
		if (parseInt(user.roll) === VISITANTE) return false;
		if (parseInt(user.roll) === SUPERADMIN) return true;

		//	const restriccionDias = parseInt(process.env.REACT_APP_SECRET_RESTRICCION_DIAS_RESERVA_EDITAR)
		//const restriccionDiasCreacion = parseInt(process.env.REACT_APP_SECRET_RESTRICCION_DIAS_RESERVA_EDITAR_CREACION)
		//const fechaReserva = moment(fecha).add(restriccionDias, 'd');
		const fechaReservaInicial = moment(fechaInicial);
		const fechaHoy = moment(moment().format("YYYY-MM-DD"));
		//const diferenciaDiasReserva = fechaReserva.diff(fechaHoy, 'days')
		const diferenciaDiasReservaInicial = fechaReservaInicial.diff(fechaHoy, 'days')

		//	console.log(diferenciaDiasReservaInicial, 'diferenciaDiasReservaInicial')
		if (diferenciaDiasReservaInicial <= 0) {
			return false;
		}

		/*if (diferenciaDiasReserva <= 0) {
			return false;
		}*/

		// if (saldosTotal?.editar) {
		// 	return false;
		// }

		return true;
	}

	// Función para realizar el pago de una reserva
	const pagarReserva = async (IdReserva, saldoReserva, IdTransaccion) => {
		let date = new Date();
		const pm_token = generateToken(date); // Reemplaza esto con tu token real

		try {
			const response = await axios({
				url: API_URL_DEV,
				method: 'POST', // Cambia a POST, PUT, etc. según sea necesario
				headers: {
					'Content-Type': 'application/json',
					'auth-token': pm_token,
				},
				data: {
					user: {
						id: IdTransaccion,
						email: user.correo,
						name: user.operador,
						last_name: user.nombre
					},
					order: {
						dev_reference: IdReserva,
						description: "Reserva",
						amount: saldoReserva,
						installments_type: 0,
						currency: "COP"
					},
					configuration: {
						partial_payment: true,
						expiration_days: 1,
						allowed_payment_methods: ["All"],
						success_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_APROBADO,
						failure_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_RECHAZADO,
						pending_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_PENDIENTE,
						review_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_PENDIENTE
					}
				},
			});

			let UrlPago = response.data.data.payment.payment_url

			window.location.href = UrlPago;


		} catch (error) {
			console.error('Hubo un error con la solicitud:', error);
		}
	};

	// Función para generar el token de autenticación
	const generateToken = (date) => {
		let server_application_code = API_LOGIN_DEV;
		let server_app_key = API_KEY_DEV;
		let unix_timestamp = Math.floor(date.getTime() / 1000);
		let uniq_token_string = server_app_key + unix_timestamp;
		let uniq_token_hash = CryptoJS.SHA256(uniq_token_string).toString();
		let auth_token = btoa(server_application_code + ";" + unix_timestamp + ";" + uniq_token_hash);
		// console.log('unix_timestamp', unix_timestamp);
		// console.log('uniq_token_string', uniq_token_string);
		// console.log('uniq_token_hash', uniq_token_hash);
		// console.log('auth_token', auth_token);

		return auth_token;
	};

	// Definir las acciones de la tabla
	const actions = [
		(item) => item.estado == "PENDIENTE" && ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faDollarSign} size="lg" /></div>,
			tooltip: "Pagar",
			onClick: (event, rowData) => handleOnClick(rowData.id, rowData.saldos, rowData.id_transaccion, rowData.idOperador, rowData.id_usuario, rowData.codigoReserva, rowData.total, item),

		}),
		(item) => ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faEye} size="lg" /></div>,
			tooltip: "Ver",
			onClick: (event, rowData) => seleccionaRegistro(item, 'detalle'),

		}), (item) => ((diferenciaDias(item.fechaReserva, item.fechaReservaInicial, item.saldos) && item.estado !== 'CANCELADO') || user.roll === '0' && item.estado !== 'CANCELADO') && ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faPencil} size="lg" /></div>,
			tooltip: "Editar",
			onClick: (event, rowData) => seleccionaRegistro(item, 'editar'),

		}), (item) => item.estado == 'PENDIENTE' && item.modificacionesReserva == 'true' && ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faFilePen} size="lg" /></div>,
			tooltip: "Ver Reserva Editada",
			onClick: (event, rowData) => {seleccionaRegistro(item, 'editardetalle')},

		}),
		(item) => diferenciaDias(item.fechaReserva, item.fechaReservaInicial, item.saldos) && item.estado !== 'CANCELADO' && ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faX} size="lg" /></div>,
			tooltip: "Cancelar",
			onClick: (event, rowData) => seleccionaRegistro(item, 'cancelar'),
		}),
		parseInt(user.roll) === SUPERADMIN && ({
			icon: () => <FontAwesomeIcon icon={faCloudArrowUp} size='sm' className='icon-btn' />,
			tooltip: "Backup",
			onClick: (event, rowData) => exportarCSV(),
			isFreeAction: true,
		})
	]

	// Función para traer datos del calendario
    const traerDatosCalendario = async (fechaInicial, fechaFinal, tipoIngreso) => {
        try {
            const { data } = await axios(ApiPaths.reservas, { params: { fechaInicial, fechaFinal, id: tipoIngreso } })
			//return data[fechaInicial]
			console.log('data', data)
			return data

        } catch (error) {
            console.log(error)
        }
    }


	const TraeReservasdelDiaHospedaje = async (startDate, idSendero) => {
		try {
			const resp = await axios(ApiPaths.informes, { params: { fecha: startDate } })
			let hospedajes = 0

			//.log('resp', resp)

			resp.data.map(item => {
				if(item.estado === "APROBADO" ){

					//comprueba si senderosDias existe, ya que en el caso de zona norte no existiría
					if (item?.senderosDias){

						let reservaDecodHospSend = decodeURIComponent(item.senderosDias); 
						let reservaJsonHospSend = JSON.parse(reservaDecodHospSend);
						let dia1Sendero = moment(reservaJsonHospSend[0].fecha).format('YYYY-MM-DD')
	
						if(dia1Sendero === startDate){
							
							//console.log('dia1Sendero',dia1Sendero, 'idSendero', idSendero)
							// let reservaDecodHosp = decodeURIComponent(item.serviciosHospedaje); 
							// let reservaJsonHosp = JSON.parse(reservaDecodHosp);
							//console.log('del dia:',item.cantidadServiciosGenericos, item)
							//let cantidadTotalHosp = reservaJsonHosp.cantidadTotal
							let cantidadTotalHosp = item.cantidadServiciosGenericos
							//console.log('cantidadTotalHosp',cantidadTotalHosp)
							hospedajes = hospedajes + cantidadTotalHosp

						}

					}


				} 
			})

			return hospedajes
		} catch (error) {
			console.log(error)
		}
	}

	const TraeReservasdelDiaHospedajeEditado = async (id) => {
			//console.log('id', id)
		try {
			const resp = await axios.get(ApiPaths.reservas, { params: { completo: 'true', id: parseInt(id), modificacionesReserva: true } })

				let reservaDecodHosp = decodeURIComponent(resp.data.serviciosHospedaje); 
				let reservaJsonHosp = JSON.parse(reservaDecodHosp);
				let cantidadTotalHosp = reservaJsonHosp.cantidadTotal
			return cantidadTotalHosp
		} catch (error) {
			console.log('error', error)
			return false
		}
	}

	const FormateaObjetoRespuesta = objeto => {
		const decodifica = decodeURIComponent(objeto);
		let convierteaJson = JSON.parse(decodifica);
		convierteaJson = convierteaJson.length === 0 ? 0 : convierteaJson;

		return convierteaJson
	}

	const TraeReservasdelDiaEditado = async (id, CuposcompletosOriginal) => {
		try {
			const resp = await axios.get(ApiPaths.reservas, { params: { completo: 'true', id: id, modificacionesReserva: true } })

				let servicioGuia = FormateaObjetoRespuesta(resp.data.servicioGuia).length
				let entradas = parseInt(resp.data.entradas)
				const totalentradas = (servicioGuia + entradas) - CuposcompletosOriginal

			return totalentradas
		} catch (error) {
			console.log(error)
		}
	}

	const PreguntasiZonaCentro = (idZona) => {

		//console.log('tiposingresos', tiposingresos)
		const sendero = tiposingresos.find(item => item.id === idZona);
		const padre = sendero?.padre ? parseInt(sendero.padre) : null

		//console.log('sendero', sendero , 'pdre', padre)
		if (padre === 3){
			return true
		}else return false
	}

	const ArmaArrayCapacidadcadaSendero = async (arrayReserva, fechaReservaInicial, fechaReservaFinal) => {
		const ArraydeDiasCuposMultiple = []
		
		// Usa Promise.all para esperar a todas las consultas async
		const resultados = await Promise.all(arrayReserva.map(async (item) => {
			const cuposActualies = await traerDatosCalendario(fechaReservaInicial, fechaReservaFinal, item.senderoId);
			//cuposActualies = cuposActualies.length === 0 ? 0 : cuposActualies;
			let cuposRevisandos = 0
			console.log('cuposActualies',cuposActualies)

			// Verifica si es un array vacío o un objeto vacío
			if (Array.isArray(cuposActualies) && cuposActualies.length === 0) {
				cuposRevisandos = 0;
			} else if (typeof cuposActualies === 'object' && Object.keys(cuposActualies).length === 0) {
				// Si es un objeto vacío, también asignamos 0
				cuposRevisandos = 0;
			} else {
				// Si no está vacío, toma el valor en la fecha formateada
				cuposRevisandos = cuposActualies[moment(item.fecha).format('YYYY-MM-DD')] || 0; // Usamos '0' como valor por defecto en caso de que no exista la fecha
			}
			
			//cuposRevisandos = cuposActualies[moment(item.fecha).format('YYYY-MM-DD')]
			console.log('a ver', {...item, cuposActualies: cuposRevisandos})

			return {...item, cuposActualies: cuposRevisandos};
		}));
		
		// Después de que todas las promesas se resuelvan, puedes usar los resultados
		ArraydeDiasCuposMultiple.push(...resultados);
		
		return ArraydeDiasCuposMultiple
		//console.log('ArraydeDiasCuposMultiple', ArraydeDiasCuposMultiple);
	}
	
	


	// Manejar el clic en una acción
	const handleOnClick = async (id, saldos, id_transaccion, idoperador, idusuario, codigoreserva, total, reserva) => {

		//console.log('reserva', reserva)
		setParametrosPago({id, saldos, id_transaccion, idoperador, idusuario, codigoreserva, total})

		try {

			let SumatotalHospedajes = 0
			let	HospedajeReservaOriginal = 0
			let	hospedajesTotalesEditado = 0
			let	capacidadHospedaje = 0
			let ArraydeDiasCuposMultiple = []
			let TieneCapacidad = false
			
			const IdzonaReserva = reserva.id_tipoingreso
			const multipleDias = reserva.multipleDias === 'true' ? true : false
			let TieneCapacidadenArray = reserva.multipleDias === 'true' ? false : true

			//console.log('reserva', reserva)
			//REVISA LA CAPACIDAD DEL SENDERO actual:
			//const reservaJsonSend = PreguntasiZonaCentro(IdzonaReserva) ? FormateaObjetoRespuesta(reserva.senderosDias) : parseInt(reserva.capacidadlimite);
			const reservaJsonSend = reserva.multipleDias === 'true' ? FormateaObjetoRespuesta(reserva.senderosDias) : parseInt(reserva.capacidadlimite);
			const idSenderoDia1 = reserva.multipleDias === 'true' ? null : reservaJsonSend


//REVISA LA CAPACIDAD DEL SENDERO ANTES DE CONFIRMAR EL PROCESO DE PAGO ---------------------------------------

			const cuposStaff = FormateaObjetoRespuesta(reserva.servicioGuia)
			// Filtrar las reservas que no sean "Guía de otra reserva"
			//console.log('cuposStaff', cuposStaff)
			const guiasFiltrados = cuposStaff.filter(reserva => reserva.guia.nombre !== "Guia de otra reserva");

			//revisa que los guias todos sean diferentes para no volverlos a contar en el cupo
			const guiasUnicas = new Set();
			guiasFiltrados.forEach(reserva => {
				guiasUnicas.add(reserva.guia.label);
			});

			//me da el numero diferente de guias:
			const cuposStaffFiltrados = guiasUnicas.size;
			//console.log('cuposStaffFiltrados', cuposStaffFiltrados)

			//revisa cuantos nuevos cupos son de la reserva original:
			const CuposcompletosOriginal = parseInt(reserva.entradas) + cuposStaffFiltrados

			//console.log('reserva.entradas', reserva.entradas)
			//console.log('reserva.servicioGuia', FormateaObjetoRespuesta(reserva.servicioGuia))

			//realiza la consulta con la función que se usa en calendario para ver la disponibilidad actual del sendero
			//console.log('idSenderoDia1', idSenderoDia1)
			//let CuposActualesSendero = await traerDatosCalendario(reserva.fechaReservaInicial, reserva.fechaReservaFinal, idSenderoDia1) 
			let CuposActualesSendero = await traerDatosCalendario(reserva.fechaReservaInicial, reserva.fechaReservaFinal === null ? reserva.fechaReservaInicial : reserva.fechaReservaFinal, IdzonaReserva) 

			console.log('CuposActualesSendero', CuposActualesSendero)
			if (CuposActualesSendero === undefined || CuposActualesSendero.length < 1){
				CuposActualesSendero = CuposcompletosOriginal
				console.log('CuposActualesSendero 1', CuposcompletosOriginal)
			}else{
				CuposActualesSendero = CuposActualesSendero[moment(reserva.fechaReservaInicial).format('YYYY-MM-DD')]
				//console.log('CuposActualesSendero 2', CuposActualesSendero)
			}

			//console.log('CuposActualesSendero 2', CuposActualesSendero)
			//console.log('CuposActualesSendero', CuposActualesSendero, reserva.fechaReservaInicial, reserva.fechaReservaFinal, )
			//console.log('CuposActualesSendero', CuposActualesSendero)
			// console.log('idSenderoDia1', reservaJsonSend[0])


			//revisa cuantos nuevos cupos son de la reserva editada:
			let CuposcompletosNuevo = 0 ;

			if (reserva.modificacionesReserva === null || reserva.modificacionesReserva === 'false'){
				CuposcompletosNuevo = CuposcompletosOriginal
			}else{CuposcompletosNuevo = await TraeReservasdelDiaEditado(reserva.id, CuposcompletosOriginal)}

			


			const CapacidadSenderoDia1 = reserva.multipleDias === 'true' ? parseInt(reservaJsonSend[0].sendero.capacidadlimite) : parseInt(reserva.capacidadlimite)
			const SumatotalCupos = CuposActualesSendero + CuposcompletosNuevo

			



//REVISA LA CAPACIDAD DEL SENDERO ANTES DE CONFIRMAR EL PROCESO DE PAGO ---------------------------------------

			console.log(' reserva', reserva)



			//igual a 2 ZOna norte, igual a 3 zona centro, entonces gestiona el HOSPEDAJE
			//if(PreguntasiZonaCentro(IdzonaReserva)){ 
			if(reserva.multipleDias === 'true'){ 
				//REVISA LA CAPACIDAD DEL HOSPEDAJE ANTES DE CONFIRMAR EL PROCESO DE PAGO
				//hace peticion y suma todos los hospedajes de la fecha enviada
				//console.log('idSenderoDia1 antes', idSenderoDia1)
				const hospedajesTotales = await TraeReservasdelDiaHospedaje(reserva.fechaReservaInicial, idSenderoDia1);
				//console.log('hospedajesTotales', hospedajesTotales)

				const reservaJsonHosp = FormateaObjetoRespuesta(reserva.serviciosHospedaje);
				HospedajeReservaOriginal = reservaJsonHosp === 0 ? 0 : reservaJsonHosp.cantidadTotal

				//consulta el total de hospedaje de la reserva nueva o editada pero pregunta si es reserva editada o no

				if (reserva.modificacionesReserva === null || reserva.modificacionesReserva === 'false'){
					hospedajesTotalesEditado = HospedajeReservaOriginal
					SumatotalHospedajes = hospedajesTotales + HospedajeReservaOriginal

				}else{
					hospedajesTotalesEditado =  await TraeReservasdelDiaHospedajeEditado(reserva.id);
					hospedajesTotalesEditado = hospedajesTotalesEditado - HospedajeReservaOriginal
					//SumatotalHospedajes = hospedajesTotales + (hospedajesTotalesEditado - HospedajeReservaOriginal)
					SumatotalHospedajes = hospedajesTotales + hospedajesTotalesEditado
					//console.log('SumatotalHospedajes aca', SumatotalHospedajes)
				}

				//revisa en servicios la capacidad
				const servicio = servicios.find(item => item.id === "84" && item.tipo === "Hospedaje"); //84 es hospedaje multiple
				capacidadHospedaje = servicio ? parseInt(servicio.capacidad) : null;

				// console.log('capacidadHospedaje', capacidadHospedaje)

				//console.log('reservaJsonSend', reservaJsonSend)
				ArraydeDiasCuposMultiple = await ArmaArrayCapacidadcadaSendero(reservaJsonSend, reserva.fechaReservaInicial, reserva.fechaReservaFinal)
				
				console.log('ArraydeDiasCuposMultiple', ArraydeDiasCuposMultiple)
				let arrayresultados = []

				ArraydeDiasCuposMultiple.map(item =>{
					if ((item.cuposActualies + CuposcompletosNuevo) <= item.sendero.capacidadlimite){
						arrayresultados.push(true)	
					}else{arrayresultados.push(false)}
				})

				const verificarValores = (array) => {
					return array.every(valor => valor === true); // Devuelve true si todos los elementos son true
				  };
				
				  // Variable que se ajusta dependiendo de la validación
				  TieneCapacidadenArray = verificarValores(arrayresultados);

				  //console.log('arrayresultados', arrayresultados)
				  //console.log('tieneCapacidad: ', TieneCapacidadenArray)

				
				//console.log('TieneCapacidadenArray', TieneCapacidadenArray)

			}

			//resumen:

			//confirma si tiene capacidad de cupos o no, para continuar el proceso
			
			if (reserva.multipleDias === 'true'){
				if (multipleDias && SumatotalHospedajes <= capacidadHospedaje){
					// console.log('multipleDias', multipleDias, 'SumatotalHospedajes', SumatotalHospedajes, 'capacidadHospedaje', capacidadHospedaje)
					// console.log('aca TieneCapacidadenArray', TieneCapacidadenArray)
					TieneCapacidad = TieneCapacidadenArray
				}
			} else {
				console.log('SumatotalCupos 2', SumatotalCupos, 'CapacidadSenderoDia1', CapacidadSenderoDia1)
				if (SumatotalCupos <= CapacidadSenderoDia1){TieneCapacidad = true}
			}


			setDatosCupos({
				SumatotalHospedajes, 
				HospedajeReservaOriginal, 
				hospedajesTotalesEditado,
				capacidadHospedaje, 
				SumatotalCupos, 
				CuposcompletosOriginal, 
				CapacidadSenderoDia1,
				CuposcompletosNuevo,
				multipleDias,
				ArraydeDiasCuposMultiple,
				TieneCapacidad
			})

			console.log('envia', {SumatotalHospedajes, 
				HospedajeReservaOriginal, 
				hospedajesTotalesEditado,
				capacidadHospedaje, 
				SumatotalCupos, 
				CuposcompletosOriginal, 
				CapacidadSenderoDia1,
				CuposcompletosNuevo,
				multipleDias,
				ArraydeDiasCuposMultiple,
				TieneCapacidad})

			console.log('final: TieneCapacidad', TieneCapacidad, 'TieneCapacidadenArray', TieneCapacidadenArray)
			if (TieneCapacidad && TieneCapacidadenArray){
				//if (true){	
				// console.log('alcanza para cupos nuevos, cupos nuevos:', SumatotalCupos, 'capacidad sendero: ', CapacidadSenderoDia1, 'actial:', CuposcompletosOriginal, 'nuevos',CuposcompletosNuevo )
				//console.log('alcanza para cupos nuevos de hospedaje, cupos nuevos:', SumatotalHospedajes, 'capacidad hospedaje: ', capacidadHospedaje, 'actual', HospedajeReservaOriginal, 'nuevas', hospedajesTotalesEditado)
				let saldosTotal = saldos ? JSON.parse(decodeURI(saldos)) : null;

				console.log('hace el proceso sii')

				if (abonos?.saldo_total > 0 && saldosTotal?.saldoReserva > 0) {

					console.log('hace el proceso sii 333')

					setPagarconSaldo(true)
					const saldoTotalAbonos = parseInt(abonos?.saldo_total)

					const saldoPendiente = (saldosTotal?.saldoReserva - saldoTotalAbonos) > 0 ? saldosTotal?.saldoReserva - saldoTotalAbonos : 0;

					const saldoPendienteFormato = saldoPendiente.toLocaleString('es-CO', {
						style: 'currency', currency: 'COP',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0
					});

					const saldoTotalAbonosFormato = saldoTotalAbonos.toLocaleString('es-CO', {
						style: 'currency', currency: 'COP',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0
					});

					setAlertaTexto(`El pago se realizara con el saldo a favor de ${saldoTotalAbonosFormato}, quedando un saldo por pagar de la reserva de ${saldoPendienteFormato}. ¿Desea continuar con el pago?`)
					setMostrarAlerta(true);

					const saldoaFavor = saldoTotalAbonos;
					const totalFacturado = saldosTotal?.saldoReserva;

					let saldoRestante = 0;
					let abonoUtilizado = 0;

					if (saldoaFavor >= totalFacturado) {
						saldoRestante = 0;
						abonoUtilizado = totalFacturado;
					} else {
						saldoRestante = totalFacturado - saldoaFavor;
						abonoUtilizado = saldoaFavor;
					}

					setSaldosPagar({
						id,
						id_transaccion,
						abonos: true,
						saldoRestante,
						abonoUtilizado,
						totalFacturado,
						idoperador,
						idusuario,
						codigoreserva,
						saldoPendiente
					})
					return;
				} else {
					setAlertaTexto(`Confirma para pasar al pago`)
					setMostrarAlerta(true);
					//pagarReserva(id, saldosTotal ? saldosTotal?.saldoReserva : total, id_transaccion)
				}	
			
			}else{
				setPagarconSaldo(false)
				setAlertaTexto(`Los cupos no son sufientes para la nueva edición`)
				setMostrarAlerta(true);
			}



		} catch (error) {
			console.error('Error al obtener hospedajes:', error);
		}

	}

	// Manejar la aceptación de la alerta
	const handleAceptar = async () => {

		let saldosTotal = parametrosPago.saldos ? JSON.parse(decodeURI(parametrosPago.saldos)) : null;

		//console.log('saldosTotal', saldosTotal)

		if(pagarconSaldo){
			const saldos = {
				nuevoTotalReserva: saldosPagar.totalFacturado,
				saldoReserva: saldosPagar.saldoRestante,
				totalReservaAnterior: 0,
				editar: false
			};
	
			if (saldosPagar.abonos) {
				const estado = saldosPagar.saldoRestante === 0 ? 'APROBADO' : 'PENDIENTE'
				let formData = new FormData()
				formData.append('fechaReserva', moment().format("YYYY-MM-DD HH:mm:ss"))
				formData.append('estadoTransaccion', estado)
				formData.append('estado', estado)
				formData.append('saldos', encodeURI(JSON.stringify(saldos)))
				formData.append('id', saldosPagar.id)
				formData.append('pagaconabonos', 'true')
				formData.append('abonoTotalUtilizado', saldosPagar.abonoUtilizado)
				formData.append('idOperador', saldosPagar.idoperador)
				formData.append('id_operador', saldosPagar.idoperador)
				formData.append('id_usuario', saldosPagar.idusuario)
				formData.append('saldoPendiente', saldosPagar.saldoPendiente)
				formData.append('codigoReserva', saldosPagar.codigoreserva)
				formData.append('METHOD', 'PUT')
	
				await axios.post(ApiPaths.reservas, formData)
	
				if (saldosPagar.saldoRestante != 0) {
					pagarReserva(saldosPagar.id, saldosPagar.saldoRestante, saldosPagar.id_transaccion)
				} else {
					toast.success('Reserva pagada con exito')
					window.location.reload();
				}
			}
			//pagarReserva(saldosPagar.id, saldosPagar.saldoRestante, saldosPagar.id_transaccion)
			// Lógica adicional para aceptar la acción

		}else{
			pagarReserva(parametrosPago.id, saldosTotal ? saldosTotal?.saldoReserva : parametrosPago.total, parametrosPago.id_transaccion)
		}
	}

	// Manejar la cancelación de la alerta
	const handleCancelar = () => {
		console.log('Acción cancelada');
		setSaldosPagar()
		// Lógica adicional para cancelar la acción
	}
	return (
		<div>
			<div className='totales-reservas'>
				{/* Mostrar totales de reservas, saldo pendiente y saldo a favor */}
				<div className='total-reservas container-totales-reservas'>
					<p>Total <b>Reservas</b></p>
					<NumericFormat displayType="text" value={abonos?.total_reservas} prefix={'$'} thousandSeparator={true} />
				</div>

				<div className='total-saldo-pendiente container-totales-reservas'>
					<p>Total <b>Saldo Pendiente</b></p>
					<NumericFormat displayType="text" value={abonos?.saldo_pendiente} prefix={'$'} thousandSeparator={true} />
				</div>

				<div className='total-saldo-favor container-totales-reservas'>
					<p>Total <b>Saldo Favor</b></p>
					<NumericFormat displayType="text" value={abonos?.saldo_total} prefix={'$'} thousandSeparator={true} />
				</div>
			</div>
			{/* Renderizar la tabla de reservas */}
			<Tabla columns={columns} data={reservas} actions={actions} title='Listado de Reservas' />
			{/* Mostrar alerta de confirmación */}
			<AlertaConfirmacion
				mostrar={mostrarAlerta}
				setMostrar={setMostrarAlerta}
				onAceptar={handleAceptar}
				onCancelar={handleCancelar}
				textoinformativo={alertaTexto}
				datosCupos={datosCupos}
			/>
		</div>
	)
}

export default VerReservas