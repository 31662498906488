import React, { useEffect, useState } from "react";
import { ApiPaths } from "../../utils";
import axios from "axios";
import ContadorVisitantes from "./Visitantes/ContadorVisitantes";

export default function ContadorVisitantesList({ totales, errors = [], value, tipoEntrada, limite }) {
    const [tarifasNacionales, setTarifasNacionales] = useState([]);
    const [tarifasExtranjeros, setTarifasExtranjeros] = useState([]);
    const [activeVisitantesNa, setActiveVisitantesNa] = useState(false)
    const [activeVisitantesEx, setActiveVisitantesEx] = useState(false)


    const peticionTraeDatos = async () => {
        try {
            if (value) {
                const nacionales = value.data.filter(({ nacionalidad }) => nacionalidad === "Nacional")
                const extranjero = value.data.filter(({ nacionalidad }) => nacionalidad === 'Extranjero')
                setTarifasNacionales(nacionales)
                setTarifasExtranjeros(extranjero)

            } else {
                // traer tarifas segun zona seleccionada
                const res = await axios.get(`${ApiPaths.tarifas}?ids=${tipoEntrada}`)

                if (Array.isArray(res.data)) {
                    setTarifasNacionales(res.data.filter(({ nacionalidad }) => nacionalidad === "Nacional"))
                    setTarifasExtranjeros(res.data.filter(({ nacionalidad }) => nacionalidad === 'Extranjero'))
                }
            }

        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => { peticionTraeDatos() }, [tipoEntrada]);

    const SumaTotales = (tarifasCounterArr) => {
        let resultado = tarifasCounterArr.reduce((acumulado, actual) => {
            let contador = actual.hasOwnProperty('contador') ? actual.contador : 0;
            return (acumulado + (contador * actual.tarifa))
        }, 0);
        return resultado;
    }

    const ContadorTotal = (tarifasCounterArr) => {
        let resultado = tarifasCounterArr.reduce((acumulado, actual) => {
            let contador = actual.hasOwnProperty('contador') ? actual.contador : 0;
            return (acumulado + contador)
        }, 0);
        return resultado;
    }

    let data = [...tarifasNacionales, ...tarifasExtranjeros];
    let total = SumaTotales(data);

    let contador = ContadorTotal(data);
    let limiteVisitantes = limite - contador;

    const updateTotalesGenerales = () => {
        if (contador == 0) {
            return totales(null)
        }
        totales({
            total,
            contador,
            data
        })
    }


    return (
        <div>
            <ContadorVisitantes
                tarifas={tarifasNacionales}
                setTarifas={setTarifasNacionales}
                condicion='Nacional'
                active={activeVisitantesNa}
                setActive={setActiveVisitantesNa}
                totales={updateTotalesGenerales}
                style={{ marginBottom: 0 }}
                errors={errors}
                disabled={limiteVisitantes <= 0 ? true : false}
            />
            <ContadorVisitantes
                errors={errors}
                tarifas={tarifasExtranjeros}
                setTarifas={setTarifasExtranjeros}
                condicion='Extranjero'
                active={activeVisitantesEx}
                setActive={setActiveVisitantesEx}
                totales={updateTotalesGenerales}
                disabled={limiteVisitantes <= 0 ? true : false}
            />
            
            
        </div>
    )
}