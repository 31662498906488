import React, { useContext, useEffect, useMemo, useCallback } from 'react'
import { Controller, useForm } from "react-hook-form";
import { Form } from 'react-bootstrap';
import TablaResumen from '../TablaResumen';
import ReservaContext from '../../../context/ReservaContext'
import { ApiPaths, TIPO_SERVICIOS } from '../../../utils';
import useTraeDatos from "../../hooks/useTraeDatos";
import ServiciosGenericos from "../Servicios/ServiciosGenericos";
import ServicioAlimentacion from "../Servicios/ServicioAlimentacion";
import moment from 'moment';

const AlimentacionHospedaje = () => {
    // Obtener funciones y estados del contexto de reserva
    const { aumentarCambioVista, serviciosTotal, retrocederCambioVista, step, cambiarServiciosTotal } = useContext(ReservaContext);

    // Configurar el formulario con valores predeterminados
    const { register, handleSubmit, watch, control, formState: { errors } } = useForm({
        defaultValues: {
            serviciosHospedaje: serviciosTotal.serviciosHospedaje,
            serviciosAlimentacion: serviciosTotal.serviciosAlimentacion
        }
    });

     const consultaFechasDisponibles = (servicio) => {
        const { fecha_deshabitado_ini, fecha_deshabitado_fin } = servicio;
        const fechaActual = moment().startOf('day'); // Fecha actual (sin horas)
    
        // Si ambas fechas son null, el servicio está disponible
        if (!fecha_deshabitado_ini && !fecha_deshabitado_fin) {
          return true;
        }
    
        // Si ambas fechas están definidas
        if (fecha_deshabitado_ini && fecha_deshabitado_fin) {
          const fechaInicio = moment(fecha_deshabitado_ini).startOf('day');
          const fechaFin = moment(fecha_deshabitado_fin).startOf('day');
    
          // Si la fecha actual está entre (o es igual a) fechaInicio y fechaFin, no mostrar
          if (fechaActual.isSameOrAfter(fechaInicio) && fechaActual.isSameOrBefore(fechaFin)) {
            return false; // No mostrar el servicio
          }
        }
    
        // Si llegamos aquí, el servicio está disponible
        return true;
    };

    // Obtener datos de servicios desde la API
    // console.log('serviciosTotal.tipoIngreso.padre', serviciosTotal.tipoIngreso.padre)
    // console.log('serviciosTotal.tipoIngreso.padre', serviciosTotal.tipoIngreso.padre)
    // console.log('serviciosTotal.tipoIngreso.id', serviciosTotal.tipoIngreso.id)
    const [servicios] = useTraeDatos(ApiPaths.servicios + "?tipo_ingreso=" + (serviciosTotal.tipoIngreso.padre ? serviciosTotal.tipoIngreso.padre : serviciosTotal.tipoIngreso.id));

    // Filtrar servicios de hospedaje y alimentación
    const serviciosHospedaje = useMemo(() => servicios.filter(({ tipo }) => tipo === TIPO_SERVICIOS.hospedaje), [servicios]);
    const serviciosHospedajeViejo = useMemo(() => servicios.filter(({ tipo }) => tipo === TIPO_SERVICIOS.hospedaje), [servicios]);
    //const serviciosAlimentacion = useMemo(() => servicios.filter(({ tipo }) => tipo === TIPO_SERVICIOS.alimentacion), [servicios]);

    const serviciosAlimentacion = useMemo(
        () =>
          servicios.filter(
            ({ tipo, ...resto }) =>
              tipo === TIPO_SERVICIOS.alimentacion && consultaFechasDisponibles(resto)
          ),
        [servicios]
      );

    // Actualizar el estado total de servicios
    const actualizarServiciosTotal = useCallback(() => {
        cambiarServiciosTotal({
            ...serviciosTotal,
            serviciosHospedaje: watch('serviciosHospedaje'),
            serviciosAlimentacion: watch('serviciosAlimentacion'),
        });
    }, [watch, cambiarServiciosTotal, serviciosTotal]);

    // Efecto para suscribirse a cambios en los servicios seleccionados
    useEffect(() => {
        const subscriptionHospedaje = watch((value, { name }) => {
            if (name === "serviciosHospedaje") actualizarServiciosTotal();
        });
        const subscriptionAlimentacion = watch((value, { name }) => {
            if (name === "serviciosAlimentacion") actualizarServiciosTotal();
        });
        return () => {
            subscriptionHospedaje.unsubscribe();
            subscriptionAlimentacion.unsubscribe();
        };
    }, [watch, actualizarServiciosTotal]);

    // Efecto para avanzar automáticamente si no hay servicios disponibles
    useEffect(() => {
        if (serviciosAlimentacion.length === 0 && serviciosHospedaje.length === 0) {
            const timer = setTimeout(() => {
                if (serviciosAlimentacion.length === 0 && serviciosHospedaje.length === 0) {
                    aumentarCambioVista();
                }
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [serviciosHospedaje, serviciosAlimentacion, aumentarCambioVista]);

    // Validar que la diferencia de días entre las fechas seleccionadas sea mayor o igual a 2
    const validarFecha = useCallback(() => {
        const diaInicial = serviciosTotal.fechas[0];
        const diaFinal = serviciosTotal.fechas[1];

        const diferenciaDias = Math.round((diaFinal - diaInicial) / (1000 * 60 * 60 * 24)) || 0;

        return diferenciaDias >= 2;
    }, [serviciosTotal.fechas]);

    // Manejar el envío del formulario
    const onSubmit = useCallback((data) => {
        aumentarCambioVista();
    }, [aumentarCambioVista]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-8'>
                        {serviciosHospedaje.length > 0 && validarFecha() && (
                            <Controller
                                control={control}
                                name="serviciosHospedaje"
                                rules={{ required: false }}
                                render={({ field: { onChange, value } }) => (
                                    <ServiciosGenericos
                                        actualizarServiciosGeneralesTotal={onChange}
                                        titulo={TIPO_SERVICIOS.hospedaje}
                                        servicios={serviciosHospedaje}
                                        serviciosTotal={serviciosTotal}
                                        value={value}
                                        errors={errors}
                                    />
                                )}
                            />
                        )}
                        {serviciosAlimentacion.length > 0 && (
                            <Controller
                                control={control}
                                name="serviciosAlimentacion"
                                rules={{ required: false }}
                                render={({ field: { onChange, value } }) => (
                                    <ServicioAlimentacion
                                        servicios={serviciosAlimentacion}
                                        titulo={TIPO_SERVICIOS.alimentacion}
                                        actualizarServiciosGeneralesTotal={onChange}
                                        serviciosTotal={serviciosTotal}
                                        value={value}
                                    />
                                )}
                            />
                        )}
                    </div>
                    <div className='col-md-4'>
                        <TablaResumen
                            serviciosTotal={serviciosTotal}
                            botonAtras={retrocederCambioVista}
                            step={step}
                        />
                    </div>
                </div>
            </div>
        </Form>
    )
}

export default AlimentacionHospedaje;
