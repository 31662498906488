import React, { useEffect, useState, useContext, useRef } from 'react'
import VerReservas from './VerReservas'
import UserContext from '../../context/UserContext'
import { SUPERADMIN, TAQUILLA, VISITANTE } from '../../constantes/roles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faPlus } from '@fortawesome/free-solid-svg-icons'

import * as RUTAS from '../../constantes/rutas';
import { ApiPaths, KeysNuvei } from '../../utils';
import { ReservaProvider } from '../../context/ReservaContext'
import BusquedaFechas from '../componentes/BusquedaFechas'
import Header from '../componentes/Header';
import moment from 'moment';
import axios from 'axios'
import { useReactToPrint } from "react-to-print";
import ReservasPrint from '../html/ReservasPrint';
import Select from 'react-select'
import SubirReservas from './SubirReservas'
import EditarReservas from './EditarReservas'
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap'
import { ExportCsv } from '@material-table/exporters'
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify'


/**
 * Componente principal para la administración de reservas.
 */
const ReservasAdmin = () => {
	const navigate = useNavigate()

	const componentRef = useRef();
	const titulo = "Reservas";
	const [tituloPag, setTituloPag] = useState(titulo)
	const [id, setId] = useState()
	const [funcion, setFuncion] = useState('ver')
	const [startDate, setStartDate] = useState(new Date());
	const [finalFecha, setFinalFecha] = useState(new Date());
	const [reservas, setReservas] = useState([])
	const [loading, setLoading] = React.useState(false);
	const [text, setText] = useState("old boring text");
	const [reservasImprimirTotales, setReservasImprimirTotales] = useState({})
	const [operadores, setOperadores] = useState([])
	const [operador, setOperador] = useState()
	const { user } = useContext(UserContext);
	const [mostrarModal, setMostrarModal] = useState(false)
	const [reservaId, setReservaId] = useState()
	const [alertaModal, setAlertaModal] = useState()
	const [reservasPrint, setReservasPrint] = useState([])
	const [estados, setEstados] = useState()
	const [medioPagos, setMedioPagos] = useState([])
	const [metodoPago, setmetodoPago] = useState()
	const [reservaSeleccionada, setReservaSeleccionada] = useState()
	const [modificacionesReserva, setModificacionesReserva] = useState(false)
	const baseUrlGeneral = window.location.origin;


	useEffect(() => {
		const operadores = async () => {
			try {
				const operadores = await axios(ApiPaths.operadores, { params: { activo: true } });
				setOperadores(operadores.data);
			} catch (error) {
				setOperador([])
			}
		}
		operadores()

		const traerMediosPagos = async () => {
			try {
				const { data } = await axios(ApiPaths.metodosPago)
				setMedioPagos(data)
			} catch (error) {
				setMedioPagos([])
				console.log(error)
			}
		}
		traerMediosPagos()
	}, [])


	useEffect(() => {
		peticionTraeDatos()
	}, [startDate, finalFecha, operador, estados, metodoPago])

	/**
	 * Realiza la petición para traer los datos de las reservas.
	 */
	const peticionTraeDatos = async () => {
		try {
			if (parseInt(user.roll) === TAQUILLA) {
				const resp = await axios.get(ApiPaths.reservas, { params: { medioPagos: metodoPago, startDate: moment(startDate).format("YYYY-MM-DD"), finalFecha: moment(finalFecha).format("YYYY-MM-DD"), estados } })
				return setReservas(resp.data)
			}
			if (parseInt(user.roll) === VISITANTE) {
				const resp = await axios.get(ApiPaths.reservas, { params: { IdVisitante: user.id, startDate: moment(startDate).format("YYYY-MM-DD"), finalFecha: moment(finalFecha).format("YYYY-MM-DD"), estados } })
				return setReservas(resp.data)
			}
			if (parseInt(user.roll) != SUPERADMIN) {
				const resp = await axios.get(ApiPaths.reservas, { params: { idUsuario: user.id_operador, startDate: moment(startDate).format("YYYY-MM-DD"), finalFecha: moment(finalFecha).format("YYYY-MM-DD"), estados } })
				return setReservas(resp.data)
			}

			const resp = await axios.get(ApiPaths.reservas, { params: { startDate: moment(startDate).format("YYYY-MM-DD"), finalFecha: moment(finalFecha).format("YYYY-MM-DD"), operador, estados } })
			setReservas(resp.data)
		} catch (error) {
			setReservas([])
			return error
		}

	}

	/**
	 * Exporta los datos de las reservas a un archivo CSV.
	 */
	const exportarCSV = async () => {
		try {
			const resp = await axios.get(ApiPaths.reservas, { params: { startDate: moment(startDate).format("YYYY-MM-DD"), finalFecha: moment(finalFecha).format("YYYY-MM-DD"), operador, estados, exportarCSV: true } })
			if (resp.data && resp.data.length > 0) {
				const cols = Object.keys(resp.data[0]).map(item => ({
					title: item,
					field: item
				}))

				ExportCsv(cols, resp.data, `Backup Reserva ${new Date()}`)
			}
		} catch (error) {
			console.log(error)
		}
	}


	// TODO: want to make this `null` default but TS complains
	const onBeforeGetContentResolve = React.useRef(Promise.resolve);

	const handleAfterPrint = React.useCallback(() => {
		console.log("`onAfterPrint jj` called"); // tslint:disable-line no-console
	}, []);

	const handleBeforePrint = React.useCallback(() => {
		console.log("`onBeforePrint` called"); // tslint:disable-line no-console
	}, []);

	const handleOnBeforeGetContent = React.useCallback(() => {
		console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console

		setLoading(true);
		setText("Loading new text...");

		return new Promise((resolve) => {
			onBeforeGetContentResolve.current = resolve;
			setTimeout(() => {
				setLoading(false);
				setText("New, Updated Text!");
				resolve();
			}, 500);
		});
	}, [setLoading, setText]);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: `Reserva - ${new Date()}`,
		onBeforeGetContent: handleOnBeforeGetContent,
		onBeforePrint: handleBeforePrint,
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true
	});

	React.useEffect(() => {
		if (text === "New, Updated Text!" && typeof onBeforeGetContentResolve.current === "function") {
			onBeforeGetContentResolve.current();
		}
	}, [onBeforeGetContentResolve.current, text]);

	const imprimir = () => {
		try {
			let totalServicioEntradas = 0;
			let totalServicioGuia = 0;
			let totalServicioHospedaje = 0;
			let totalServicioAlimentacion = 0;
			let totalServicioVehiculo = 0;
			let totalReservas = 0;
			const reservaArray = []
			reservas && reservas.map(reserva => {
				if (reserva.estado === 'COMPLETADO' || reserva.estado === 'APROBADO') {
					totalServicioGuia += parseInt(reserva.totalServicioGuia)
					totalServicioHospedaje += parseInt(reserva.totalServiciosGenericos)
					totalServicioAlimentacion += parseInt(reserva.totalServicioAlimentacion)
					totalServicioEntradas += parseInt(reserva.totalEntradas)
					totalServicioVehiculo += parseInt(reserva.totalServicioVehiculo)
					totalReservas += parseInt(reserva.total)
					reservaArray.push(reserva)
				}

			})
			setReservasPrint(reservaArray)
			setReservasImprimirTotales({
				totalServicioAlimentacion,
				totalServicioGuia,
				totalServicioHospedaje,
				totalServicioEntradas,
				totalServicioVehiculo,
				totalReservas
			})
			handlePrint()
		} catch (error) {
			console.log(error)
		}

	}

	const seleccionaRegistro = async (...args) => {
		try {

			if (args[1] === "detalle") {
				navigate(args[0].id)
			}

			if (args[1] === "editardetalle") {
				navigate(args[0].id, { state: { modificacionesReserva: args[0].modificacionesReserva } })
			}

			if (args[1] === "eliminar") {
				const baseUrl = ApiPaths.reservas;
				let formData = new FormData()
				formData.append('id', args[0].id)
				formData.append('METHOD', 'DELETE')
				const respuesta = await axios.post(baseUrl, formData)
				setReservas(respuesta.data)
			}

			if (args[1] === "editar") {
				setFuncion('editar')
				setModificacionesReserva(args[0].modificacionesReserva)
				setId(args[0].id)
			}

			if (args[1] === "cancelar") {
				const baseUrl = ApiPaths.condiciones;
				const { data } = await axios(baseUrl)
				const respuesta = JSON.parse(decodeURI(data.cancelacionReservas))
				setAlertaModal(respuesta)
				setReservaId(args[0].id)
				setReservaSeleccionada(args[0]);
				setMostrarModal(true)
			}

		} catch (error) {
			console.log(error)
		}
	}

	const confirmarCancelacion = async () => {
		try {
			const baseUrl = ApiPaths.reservas;
			let formData = new FormData()
			formData.append('id', reservaId)
			formData.append('id_operador', reservaSeleccionada.idOperador)
			formData.append('id_usuario', user.id)
			formData.append('codigo_reserva', reservaSeleccionada.codigoReserva)
			formData.append('cancelar', "CANCELADO")
			formData.append('METHOD', 'PUT')
			await axios.post(baseUrl, formData)

			setStartDate(new Date())
			setMostrarModal(false)
		} catch (error) {
			console.log(error)
		}

	}

	const API_LOGIN_DEV = KeysNuvei.API_LOGIN_DEV;
	const API_KEY_DEV = KeysNuvei.API_KEY_DEV;
	const generateToken = (date) => {
		let server_application_code = API_LOGIN_DEV;
		let server_app_key = API_KEY_DEV;
		let unix_timestamp = Math.floor(date.getTime() / 1000);
		let uniq_token_string = server_app_key + unix_timestamp;
		let uniq_token_hash = CryptoJS.SHA256(uniq_token_string).toString();
		let auth_token = btoa(server_application_code + ";" + unix_timestamp + ";" + uniq_token_hash);

		return auth_token;
	};

	const PagarReserva = async () => {
		let date = new Date();
		const pm_token = generateToken(date); // Reemplaza esto con tu token real
		let Total = 1000

		try {
			const response = await axios({
				url: 'https://noccapi-stg.paymentez.com/linktopay/init_order/',
				method: 'POST', // Cambia a POST, PUT, etc. según sea necesario
				headers: {
					'Content-Type': 'application/json',
					'auth-token': pm_token,
				},
				data: {
					user: {
						id: '1',
						email: 'email@example.com',
						name: 'person',
						last_name: 'John',
					},
					order: {
						dev_reference: "123456789",
						description: "Reserva",
						amount: Total,
						installments_type: 0,
						currency: "COP"
					},
					configuration: {
						partial_payment: true,
						expiration_days: 1,
						allowed_payment_methods: ["All"],
						success_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_APROBADO,
						failure_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_RECHAZADO,
						pending_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_PENDIENTE,
						review_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_PENDIENTE
					}
				},
			});

			let UrlPago = response.data.data.payment.payment_url

			//console.error('response.data', response.data);

		} catch (error) {
			console.error('Hubo un error con la solicitud:', error);
		}
	};


	return (
		<div>
			<Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} Pagina={'Operadores'} Agregar={true} >
				<button
					onClick={imprimir}
					className="btn boton_estandar_blanco float-right"
				><FontAwesomeIcon icon={faClipboardList} size="lg" />
				</button>

				{/* <button
					onClick={PagarReserva}
					className="btn boton_estandar_blanco float-right"
				>pagar
				</button> */}

				<BusquedaFechas
					startDate={startDate}
					setStartDate={setStartDate}
					finalFecha={finalFecha}
					setFinalFecha={setFinalFecha}
				/>
				{parseInt(user.roll) === SUPERADMIN && <div className='d-flex select-container '>
					<Select
						clearValue={() => 0}
						isClearable
						name="color"
						placeholder="Operador"
						options={operadores && operadores.map(item => ({ label: item.nombre, value: item.id }))}
						className="react-select-container"
						classNamePrefix="react-select"
						onChange={(e) => e ? setOperador(parseInt(e.value)) : setOperador(null)}
					/>
				</div>}
				<div className='d-flex select-container '>
					<Select
						clearValue={() => 0}
						isClearable
						placeholder="Estado"
						options={[{ label: 'PENDIENTE', value: 'PENDIENTE' }, { label: 'APROBADO', value: 'APROBADO' }, { label: 'RECHAZADO', value: 'RECHAZADO' }, { label: 'CANCELADO', value: 'CANCELADO' }, { label: 'COMPLETADO', value: 'COMPLETADO' }]}
						className="react-select-container ml-1"
						classNamePrefix="react-select"
						onChange={(e) => e ? setEstados(e.value) : setEstados(null)}
					/>
				</div>
				{parseInt(user.roll) === TAQUILLA &&
					<div className='d-flex select-container '>
						<Select
							clearValue={() => 0}
							isClearable
							placeholder="Metodo Pago"
							options={medioPagos && medioPagos.map(item => ({
								value: item.nombre, label: item.nombre
							}))}
							className="react-select-container ml-1"
							classNamePrefix="react-select"
							onChange={(e) => e ? setmetodoPago(e.value) : setmetodoPago(null)}
						/>
					</div>
				}
			</Header>

			{funcion === 'crear' &&
				<ReservaProvider>
					<SubirReservas />
				</ReservaProvider>
			}
			{funcion === 'ver' &&
				<VerReservas reservas={reservas} seleccionaRegistro={seleccionaRegistro} exportarCSV={exportarCSV} />
			}
			{funcion === 'editar' &&
				<ReservaProvider>
					<EditarReservas id={id} modificacionesReserva={modificacionesReserva} />
				</ReservaProvider>
			}
			<div style={{ display: 'none' }}> <ReservasPrint ref={componentRef} data={reservasPrint} reservasImprimirTotales={reservasImprimirTotales} /></div>

			<Modal show={mostrarModal} onHide={() => setMostrarModal(false)}>

				<Modal.Body> <div dangerouslySetInnerHTML={{ __html: alertaModal }}></div></Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setMostrarModal(false)}>
						Cancelar
					</Button>
					<Button variant="primary" onClick={confirmarCancelacion}>
						Confirmar
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	)
}

export default ReservasAdmin