import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import Header from '../componentes/Header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApiPaths } from '../../utils.js';
import * as RUTAS from '../../constantes/rutas';
import UserContext from '../../context/UserContext';
import { SUPERADMIN } from '../../constantes/roles';
import { Form } from 'react-bootstrap';
const EditarTickets = () => {

	const { state: usuarioSelect, ...location } = useLocation()
	const { id } = useParams()
	const { user } = useContext(UserContext);
	const navigate = useNavigate()
	const [mensajes, setMensajes] = useState(
		[
			{ text: usuarioSelect.detalle, id_operador: usuarioSelect.operadorId, fecha: usuarioSelect.fecha, usuarioNombre: usuarioSelect.usuarioNombre, operadorNombre: usuarioSelect.operadorNombre },
			usuarioSelect.respuesta && { text: usuarioSelect.respuesta, id_operador: '4', operadorNombre: 'Union Temporal', fecha: usuarioSelect.fecha }
		].filter(Boolean) // Filtra valores falsy (como "", null, undefined, false)
	);
	const [nuevoMensaje, setNuevoMensaje] = useState('');

	useEffect(() => {
		const peticionDatos = async () => {
			try {
				const resp = await axios.get(ApiPaths.tickets, { params: { id_ticket: id } })
				setMensajes([...mensajes, ...resp.data]);
			}
			catch (error) {
				console.log(error)
			}
		}
		peticionDatos()
	}, [])

	//almacena toda la data de  
	const [registro, setRegistro] = useState(usuarioSelect)

	if (!usuarioSelect) return <p>Error al ingresar</p>
	//Busca Id de Operador para añadirlo a tabla de usuarios como relación
	const peticionPut = async (e) => {
		e.preventDefault();
		try {
			const { estado } = registro
			const f = new FormData()
			f.append('estado', estado)
			f.append("METHOD", "PUT")
			await axios.post(ApiPaths.tickets, f, { params: { id: registro.id } })


			const form = new FormData()
			form.append('respuesta', `El ticket ha sido cambiado a estado ${estado}`)
			form.append('id_operador', user.id_operador)
			form.append('id_usuario', user.id)
			form.append("METHOD", "PUT")
			await axios.post(ApiPaths.tickets, form, { params: { id: registro.id } })

			navigate(RUTAS.ADMIN_TICKETS)
		} catch (error) {
			console.log(error)
		}
	}

	const handleSendMessage = async (e) => {
		e.preventDefault();
		if (nuevoMensaje.trim()) {
			try {
				const f = new FormData()
				f.append('respuesta', nuevoMensaje)
				f.append('id_operador', user.id_operador)
				f.append('id_usuario', user.id)
				f.append("METHOD", "PUT")
				await axios.post(ApiPaths.tickets, f, { params: { id: registro.id } })
			}
			catch (error) {
				console.log(error)
			}
			setMensajes([...mensajes, { text: nuevoMensaje, id_operador: user.operadorId }]);
			setNuevoMensaje('');
		}
	};

	console.log('mensajes', mensajes)

	return (
		<div >
			<Header titulo='Editar Tickets' funcion='regresar' onClick={() => navigate(RUTAS.ADMIN_TICKETS)} Pagina={'Editar Tickets'} Agregar={true} />
			<div className="form-row">
				<div className="col-6">
					<div className="md-form md-outline my-2">
						<input data-test="input" readOnly type="text" className="form-control input-ticket" name="Usuario" aria-disabled="false" defaultValue={registro.usuarioNombre} />
						<label className="active" data-error data-success id aria-labelledby>Usuario</label>
					</div>
				</div>
				<div className="col-6">
					<div className="md-form md-outline my-2">
						<input data-test="input" readOnly type="text" className="form-control input-ticket" name="Operador" aria-disabled="false" defaultValue={registro.operadorNombre} />
						<label className="active" data-error data-success id aria-labelledby>Operador</label>
					</div>
				</div>
			</div>
			<div className="form-row">
				<div className="col-6">
					<div className="md-form md-outline my-2">
						<input data-test="input" readOnly type="text" className="form-control input-ticket" name="Asunto" aria-disabled="false" defaultValue={registro.nombre} />
						<label className="active" data-error data-success id aria-labelledby>Asunto</label>
					</div>
				</div>
				<div className="col-6">
					<div className="md-form md-outline my-2">
						<input data-test="input" readOnly type="text" className="form-control input-ticket" name="Dirigido" aria-disabled="false" defaultValue={registro.dirigido} />
						<label className="active" data-error data-success id aria-labelledby>Dirigido a</label>
					</div>
				</div>
			</div>
			<div className="form-row">
				<div className="col-6">
					<div className="md-form md-outline my-2">
						<input data-test="input" readOnly type="text" className="form-control input-ticket" aria-disabled="false" defaultValue={registro.codigoReserva} />
						<label className="active" data-error data-success id aria-labelledby>Codigo de reserva</label>
					</div>
				</div>

				<div className="col-6">
					<Form onSubmit={peticionPut}>
						{parseInt(user.roll) === SUPERADMIN ?
							<div className='row'>
								<div className='col-7 select-ticket'>
									<label >Estado Ticket</label>
									<select
										className={"form-control custom-select"}
										name="estado"
										required
										value={registro.estado}
										onChange={e => setRegistro({ ...registro, estado: e.target.value })}>
										<option value={""}>Cambiar estado de Ticket</option>
										<option value="APROBADO">Aprobado</option>
										<option value="PENDIENTE">Pendiente</option>
										<option value="RECHAZADO">Rechazado</option>
									</select>

								</div>
								<div className="col-5 text-center">
									<button
										type='submit'
										className="btn boton_estandar"
									>Cambiar Estado
									</button>
								</div>
							</div> : null}
					</Form>
				</div>

			</div>

			<div className="form-row">
				<div className="col-6">
					<div className="md-form md-outline my-2">
						<input data-test="input" readOnly type="text" className="form-control input-ticket" name="Dirigido" aria-disabled="false" defaultValue={registro.estado} />
						<label className="active" data-error data-success id aria-labelledby>Estado</label>
					</div>
				</div>
				<div className="col-6">
					<div className="md-form md-outline my-2 ml-2">
						{registro.pdf ? <a href={ApiPaths.tickets + registro.pdf} target="_blank">Ver Archivo Adjunto</a> : null}
					</div>

				</div>
			</div>
			<div className='form-row'>
				<div className="col-6">
					<div className="md-form md-outline my-2">
						<input data-test="input" readOnly type="text" className="form-control input-ticket" name="Dirigido" aria-disabled="false" defaultValue={registro.codigoTicket} />
						<label className="active" data-error data-success id aria-labelledby>Codigo</label>
					</div>
				</div>
				<div className="col-6">
					<div className="md-form md-outline my-2">
						<input data-test="input" readOnly type="text" className="form-control input-ticket" name="Dirigido" aria-disabled="false" defaultValue={registro.fecha} />
						<label className="active" data-error data-success id aria-labelledby>Fecha</label>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card card-tickets shadow-sm" style={{ borderRadius: '20px' }}>
						<div className="card-header bg-primary text-white" style={{ borderRadius: '20px 20px 0 0' }}>
							Información de Ticket
						</div>
						<div className="card-body" style={{ height: '400px', overflowY: 'scroll', backgroundColor: '#f7f7f7', borderRadius: '0 0 20px 20px' }}>
							<ul className="list-unstyled">
								{mensajes && mensajes.map((msg, index) => (
									<li
										key={index}
										className={`d-flex ${(user.operadorId === msg.id_operador) ? 'justify-content-end' : 'justify-content-start'} mb-4`}
									>

										<div className="d-inline-block" style={{ maxWidth: '70%' }}>
											<div className={`p-3 rounded-lg shadow-sm ${(user.operadorId === msg.id_operador) ? 'bg-light ' : 'bg-white'}`} style={{ whiteSpace: 'pre-wrap' }}>
												{msg.text}
												<br />
												<strong className="d-block">{msg.usuarioNombre}</strong>
												<small >{msg.fecha}</small>
											</div>
										</div>
									</li>
								))}
							</ul>
						</div>
						{registro.estado === "PENDIENTE" ? (
							<div className="card-footer bg-light" style={{ borderRadius: '0 0 20px 20px' }}>
								<form onSubmit={handleSendMessage}>
									<div className="input-group align-items-center">
										<input
											type="text"
											className="form-control"
											value={nuevoMensaje}
											onChange={(e) => setNuevoMensaje(e.target.value)}
											placeholder="Escribe tu mensaje..."
											style={{ borderRadius: '20px 0 0 20px' }}
										/>
										<div className="input-group-append">
											<button className="btn btn-primary" type="submit" style={{ borderRadius: '0 20px 20px 0' }}>
												Enviar
											</button>
										</div>
									</div>
								</form>
							</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	)
}

export default EditarTickets