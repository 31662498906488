import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleCheck, faCalendarDays, faBed, faFilePen, faPencil, faTrashAlt, faX } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';


export const AlertaConfirmacion = ({ mostrar, setMostrar, datosCupos, onAceptar = () => { }, onCancelar = () => { }, textoinformativo = '¿Está seguro de realizar esta acción?', children, textotitulo = 'Confirmación de acción' }) => {

  const handleAceptar = () => {
    onAceptar()
    setMostrar(false)
  }

  const handleCancelar = () => {
    onCancelar()
    setMostrar(false)
  }

  const Simbolo = (numero) => {
    if (numero > 0){
      return `+${numero}`
    }else if(numero === 0){
      return numero
    }else{return `-${numero}`}
  }

  const ValidaIcono = (cupos, capacidad) =>{
    if (cupos > capacidad){
      return <FontAwesomeIcon icon={faCircleXmark} size="small" style={{color: 'red'}}/>
    }else{
      return <FontAwesomeIcon icon={faCircleCheck} size="small" style={{color: 'green'}}/>
    }
  }

  // console.log('datosCupos', datosCupos)

  return (
    datosCupos !== undefined ? 
      (
      <Modal show={mostrar} onHide={handleCancelar}>
        <Modal.Header closeButton>
          <Modal.Title>{textotitulo}</Modal.Title> </Modal.Header>
        <Modal.Body>
          {children ? children : <h5>{textoinformativo}</h5>}
          {datosCupos.ArraydeDiasCuposMultiple.length > 0 ?
            <>
              <h5><FontAwesomeIcon icon={faCalendarDays} size="small"/> <b>Cupos del Sendero:</b></h5>
              {datosCupos.ArraydeDiasCuposMultiple.map((item, index) =>{
                return(
                  <div key={index}>
                    <hr/>
                    <span><b>Sendero {index +1}: </b>{item.sendero.nombre} | <b>Fecha: </b>{moment(item.fecha).format('DD-MM-YYYY')} </span><br />
                    <span><b>Cupos reserva Actual: </b>{datosCupos.CuposcompletosOriginal} | <b>Cupos Adicionales: </b>{Simbolo(datosCupos.CuposcompletosNuevo)} </span><br />
                    <span><b>Capacidad Estimada: </b>{item.cuposActualies + datosCupos.CuposcompletosNuevo}/{item.sendero.capacidadlimite} {ValidaIcono(item.cuposActualies + datosCupos.CuposcompletosNuevo, item.sendero.capacidadlimite)}</span><br />
                  </div>
                )
              })}
            </>
          :
          <>
              <hr/>
              <h5><FontAwesomeIcon icon={faCalendarDays} size="small"/> <b>Cupos del Sendero:</b></h5>
              <span><b>Cupos reserva Actual: </b>{datosCupos.CuposcompletosOriginal} | <b>Cupos Adicionales: </b>{Simbolo(datosCupos.CuposcompletosNuevo)} </span><br />
              <span><b>Capacidad Estimada: </b>{datosCupos.SumatotalCupos}/{datosCupos.CapacidadSenderoDia1} {ValidaIcono(datosCupos.SumatotalCupos, datosCupos.CapacidadSenderoDia1)}</span><br />
          </>
          }
          {/* si es zona centro que es 3 ---- HOSPEDAJE: ----------------------------*/}
          {datosCupos.multipleDias ?
            <>
              <hr/>
              <h5><FontAwesomeIcon icon={faBed} size="small"/> <b>Cupos del Hospedaje:</b></h5>
              <span><b>Cupos reserva Actual: </b>{datosCupos.HospedajeReservaOriginal} | <b>Cupos Adicionales: </b>{Simbolo(datosCupos.hospedajesTotalesEditado)} </span><br />
              <span><b>Capacidad Estimada: </b>{datosCupos.SumatotalHospedajes}/{datosCupos.capacidadHospedaje} {ValidaIcono(datosCupos.SumatotalHospedajes, datosCupos.capacidadHospedaje)} </span><br />
            </>
          :null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelar}>
            Cancelar
          </Button>
          {datosCupos.multipleDias ? 
            <>
              {datosCupos.TieneCapacidad ?
                <Button variant="primary" onClick={handleAceptar}>
                    Confirmar
                  </Button>
              :null}
            </>
          :
          <>
            {datosCupos.SumatotalCupos <= datosCupos.CapacidadSenderoDia1 ?
              <Button variant="primary" onClick={handleAceptar}>
                  Confirmar
                </Button>
            :null}
          </>
          }
          
        </Modal.Footer>
      </Modal>)
    :
    <Modal show={mostrar} onHide={handleCancelar}>
    <Modal.Header closeButton>
      <Modal.Title>{textotitulo}</Modal.Title> </Modal.Header>
    <Modal.Body>
      {children ? children : <h4>{textoinformativo}</h4>}

    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCancelar}>
        Cancelar
      </Button>
      <Button variant="primary" onClick={handleAceptar}>
        Confirmar
      </Button>
    </Modal.Footer>
  </Modal>
  )
}
