import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicket, faCalendarDays, faCreditCardAlt, faUsers, faUser, faBinoculars, faVanShuttle, faHouse, faTaxi, faCheck, faXmark, faFileDownload, faWalking, faHiking } from '@fortawesome/free-solid-svg-icons'
import { NumericFormat } from 'react-number-format'
import moment from 'moment'
import ReservaContext from '../../context/ReservaContext'
import useTraeDatos from "../hooks/useTraeDatos";
import ServicioAlimentacion from './Servicios/ServicioAlimentacion'
import { ApiPaths, TIPO_SERVICIOS, KeysNuvei } from "../../utils";
import axios from 'axios'

import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import * as RUTAS from '../../constantes/rutas';
import UserContext from '../../context/UserContext'
import { SUPERADMIN, TAQUILLA, VISITANTE } from '../../constantes/roles'
import CryptoJS from 'crypto-js';

export const InformacionPago = ({ pagar = true }) => {
    // Obtener el contexto del usuario y la reserva
    const { user } = useContext(UserContext)
    const { aumentarCambioVista, serviciosTotal, retrocederCambioVista, step, cambiarServiciosTotal } = useContext(ReservaContext)

    // Verificar si el sendero es múltiple
    const senderoMultiple = serviciosTotal.tipoIngreso.senderoMultiple === 'true';

    // Obtener datos de servicios y abonos
    const [servicios] = useTraeDatos(ApiPaths.servicios + "?tipo_ingreso=" + (serviciosTotal?.tipoIngreso?.padre ? serviciosTotal.tipoIngreso.padre : serviciosTotal.tipoIngreso.id));

    const [abonos] = useTraeDatos(ApiPaths.operadoresAbonos, { idOperador: user?.operadorId })
    const navigate = useNavigate()
    const [cargandoBoton, setCargandoBoton] = useState(false)

    // Variables de configuración de la API
    const API_LOGIN_DEV = KeysNuvei.API_LOGIN_DEV;
    const API_KEY_DEV = KeysNuvei.API_KEY_DEV;
    const API_URL_DEV = KeysNuvei.API_URL_DEV;

    const baseUrlGeneral = window.location.origin;

    // Variables para cálculos de pagos y abonos
    let sumaTotal = 0;
    let sumaTotalPagar = 0;
    let sumaTotalPagado = 0;
    let abonoTotalUtilizado = 0;

    let abonosSaldoTotal = parseInt(abonos.saldo_total);

    if (abonosSaldoTotal > 0) {

        let sumaTotalProvi = (serviciosTotal.totalEntradas ? serviciosTotal.totalEntradas : 0) + (serviciosTotal.totalSeguros ? serviciosTotal.totalSeguros : 0) + (serviciosTotal.serviciosAlimentacion?.totalServicioAlimentacion ? serviciosTotal.serviciosAlimentacion?.totalServicioAlimentacion : 0) + (serviciosTotal.totalServicioGuia ? serviciosTotal.totalServicioGuia : 0) + (serviciosTotal.totalServicioVehiculo ? serviciosTotal.totalServicioVehiculo : 0) + (serviciosTotal.serviciosHospedaje?.totalServiciosGenericos ? serviciosTotal.serviciosHospedaje?.totalServiciosGenericos : 0)

        const saldoaFavor = abonosSaldoTotal;
        const totalFacturado = sumaTotalProvi;

        let saldoRestante = 0;
        let abonoUtilizado = 0;

        sumaTotal = totalFacturado
        if (serviciosTotal?.id && serviciosTotal?.estado == 'APROBADO') {
            if (saldoaFavor >= sumaTotalPagar) {
                saldoRestante = 0;
                abonoUtilizado = sumaTotalPagar;
                sumaTotalPagar = 0;
            } else {
                saldoRestante = sumaTotalPagar - saldoaFavor;
                abonoUtilizado = saldoaFavor - sumaTotalPagar;
            }

        } else if (saldoaFavor >= totalFacturado) {
            saldoRestante = 0;
            abonoUtilizado = totalFacturado;
        } else {
            saldoRestante = totalFacturado - saldoaFavor;
            abonoUtilizado = saldoaFavor;
        }

        if (serviciosTotal?.id && serviciosTotal?.estado == 'APROBADO') {
            sumaTotalPagado = serviciosTotal.total
        } else {
            sumaTotalPagado = serviciosTotal.totalAbonos + serviciosTotal?.totalPagado
        }

        if (serviciosTotal.id) {

            if (sumaTotal == serviciosTotal.total && serviciosTotal?.estado == 'PENDIENTE') {
                sumaTotalPagar = (sumaTotal - abonoUtilizado) - serviciosTotal?.totalPagado
                abonoTotalUtilizado = abonoUtilizado
            } else if (sumaTotal < serviciosTotal.total && serviciosTotal?.estado == 'APROBADO') {
                sumaTotalPagar = sumaTotal - serviciosTotal.total
            } else if (sumaTotal < serviciosTotal.total) {
                sumaTotalPagar = (sumaTotal - abonoUtilizado) - serviciosTotal?.totalPagado
                abonoTotalUtilizado = abonoUtilizado
            } else {
                sumaTotalPagar = sumaTotal - serviciosTotal.total
            }
        } else {
            sumaTotalPagar = saldoRestante
            abonoTotalUtilizado = abonoUtilizado
        }
    } else {

        sumaTotal = (serviciosTotal.totalEntradas ? serviciosTotal.totalEntradas : 0) + (serviciosTotal.totalSeguros ? serviciosTotal.totalSeguros : 0) + (serviciosTotal.serviciosAlimentacion?.totalServicioAlimentacion ? serviciosTotal.serviciosAlimentacion?.totalServicioAlimentacion : 0) + (serviciosTotal.totalServicioGuia ? serviciosTotal.totalServicioGuia : 0) + (serviciosTotal.totalServicioVehiculo ? serviciosTotal.totalServicioVehiculo : 0) + (serviciosTotal.serviciosHospedaje?.totalServiciosGenericos ? serviciosTotal.serviciosHospedaje?.totalServiciosGenericos : 0)
        // console.log('inrgeso acaaaa')
        sumaTotalPagar = sumaTotal
        if (serviciosTotal?.estado == 'PENDIENTE' && serviciosTotal?.modificacionesReserva == "true") {
            sumaTotalPagado = serviciosTotal?.totalPagado
            sumaTotalPagar = sumaTotal - sumaTotalPagado
        } else if (serviciosTotal.id && serviciosTotal?.estado == 'PENDIENTE') {
            sumaTotalPagar = sumaTotal - (serviciosTotal?.totalAbonos || 0)

        } else if (serviciosTotal?.id && serviciosTotal?.estado == 'APROBADO' || serviciosTotal?.id && serviciosTotal?.estado == 'COMPLETADO') {
            sumaTotalPagado = serviciosTotal.total
            sumaTotalPagar = sumaTotal - serviciosTotal.total

        }
    }

    const baseUrl = ApiPaths.reservas;

    const serviciosAlimentacion = servicios.filter(({ tipo }) => tipo === TIPO_SERVICIOS.alimentacion);

    // Función para arreglar la fecha
    const arreglarFecha = (fecha) => moment(fecha).format("YYYY-MM-DD");

    // Función para arreglar la fecha del pedido
    const arreglarFechaPedido = (fecha) => moment(fecha).format("YYYY-MM-DD h:mm:ss")

    // Función para calcular la edad
    const calcularEdad = (fecha) => {
        if (fecha.trim() === "") return null;

        const nacimiento = moment(fecha);
        const hoy = moment();
        return hoy.diff(nacimiento, "years");
    }


    // Función para verificar los datos y mostrar un ícono
    const verificarDatosIcon = (dato) => {
        if (dato.trim() === "") return <FontAwesomeIcon icon={faXmark} size="1x" style={{ marginRight: 0, color: 'rgb(255, 204, 0)' }} />

        return <FontAwesomeIcon icon={faCheck} className="textVerde" size="1x" style={{ marginRight: 0 }} />
    }


    // Función para verificar el estado de la reserva
    const verificarEstado = () => {
        if (sumaTotalPagar <= 0) {
            return 'APROBADO'
        }
        if (user && parseInt(user.roll) === TAQUILLA) {
            return 'COMPLETADO'
        }
        let estado = 'PENDIENTE'
        serviciosTotal.personas.map(persona => {
            if (persona.numeroDocumento.trim() == "") {
                estado = 'PENDIENTE'
            }
            if (persona.primerApellido.trim() == "") {
                estado = 'PENDIENTE'
            }
            if (persona.segundoApellido.trim() == "") {
                estado = 'PENDIENTE'
            }
            if (persona.nombre.trim() == "") {
                estado = 'PENDIENTE'
            }
            if (persona.fechaNacimiento.trim() == "") {
                estado = 'PENDIENTE'
            }
            if (persona.tipoDocumento.trim() == "") {
                estado = 'PENDIENTE'
            }

            if (persona.codigoSeguro.trim() == "") {
                estado = 'PENDIENTE'
            }
        })

        return estado;
    }

    const saldos = {
        nuevoTotalReserva: sumaTotal,

        saldoReserva: (serviciosTotal?.estado == 'PENDIENTE' && serviciosTotal?.modificacionesReserva == "true") ? sumaTotalPagar : (serviciosTotal?.estado == 'PENDIENTE') ? sumaTotalPagar : (serviciosTotal?.estado == 'PENDIENTE') ? sumaTotal : sumaTotalPagar ? sumaTotalPagar : (sumaTotalPagar == 0) ? sumaTotalPagar : serviciosTotal?.total ? (sumaTotal - serviciosTotal.total) : sumaTotal,

        totalReservaAnterior: serviciosTotal?.estado == 'PENDIENTE' && serviciosTotal?.modificacionesReserva == "true" ? sumaTotalPagado : serviciosTotal?.estado == 'PENDIENTE' ? 0 : serviciosTotal?.total ? serviciosTotal.total : 0,

        editar: serviciosTotal?.estado ? true : false
    };

    // console.log(serviciosTotal, 'serviciosTotal')

    // Función para subir la reserva
    const subirReserva = async () => {

        console.log('serviciosTotal', serviciosTotal)

        setCargandoBoton(true)
        try {
            if (!user) {
                let formData = new FormData()
                formData.append('fechaReserva', moment(serviciosTotal.fechaReserva).format("YYYY-MM-DD HH:mm:ss"))
                formData.append('estado', verificarEstado())
                formData.append('fechaReservaInicial', moment(serviciosTotal.fechas[0]).format("YYYY-MM-DD HH:mm:ss"))
                formData.append('fechaReservaFinal', serviciosTotal.fechas[1] ? moment(serviciosTotal.fechas[1]).format("YYYY-MM-DD HH:mm:ss") : '')
                formData.append('totalEntradas', serviciosTotal.totalEntradas)
                formData.append('totalSeguros', serviciosTotal.totalSeguros)
                formData.append('totalServicioAlimentacion', serviciosTotal.serviciosAlimentacion?.totalServicioAlimentacion || 0)
                formData.append('totalServicioGuia', serviciosTotal.totalServicioGuia)
                formData.append('totalServicioVehiculo', serviciosTotal.totalServicioVehiculo || 0)
                formData.append('totalServiciosGenericos', serviciosTotal.serviciosHospedaje?.totalServiciosGenericos || 0)
                formData.append('servicioVehiculo', encodeURI(JSON.stringify(serviciosTotal.servicioVehiculos || [])))
                formData.append('servicioGuia', encodeURI(JSON.stringify(serviciosTotal.servicioGuia || [])))
                formData.append('serviciosAlimentacion', encodeURI(JSON.stringify(serviciosTotal.serviciosAlimentacion?.serviciosAlimentacion || [])))
                formData.append('serviciosHospedaje', encodeURI(JSON.stringify(serviciosTotal.serviciosHospedaje || [])))
                formData.append('contarServicios', encodeURI(JSON.stringify(serviciosTotal.serviciosAlimentacion?.contarServicios || [])))
                formData.append('entradas', serviciosTotal.entradas)
                formData.append('id_usuario', serviciosTotal.registro.id)
                formData.append('total', sumaTotal)
                formData.append('id_tipoingreso', serviciosTotal.tipoIngreso.id)
                formData.append('personas', encodeURI(JSON.stringify(serviciosTotal.personas)))
                formData.append('estadoTransaccion', verificarEstado())
                formData.append('metodoPago', sumaTotalPagar == 0 ? "SALDO" : serviciosTotal.mediosPago ? serviciosTotal.mediosPago : 'NUVEI')
                formData.append('codigoReserva', moment().format("PMMDDYYYYhhmmss"))
                formData.append('abonoTotalUtilizado', abonoTotalUtilizado)
                formData.append('idOperador', user?.operadorId)
                formData.append('restriccionesAlimenticias', serviciosTotal?.serviciosAlimentacion?.restriccionesAlimenticias || '')
                formData.append('METHOD', 'POST')
                const respuesta = await axios.post(baseUrl, formData)
                //console.log('respuesta', respuesta.data.id)

                PagarReserva(respuesta.data.id, respuesta.data.id_transaccion)
                //navigate('/')
                //toast.success("Reserva creada con éxito!,ingresa con tu usuario y clave para más información")
            } else {
                let formData = new FormData()
                formData.append('fechaReserva', moment(serviciosTotal.fechaReserva).format("YYYY-MM-DD HH:mm:ss"))
                formData.append('estado', verificarEstado())
                formData.append('fechaReservaInicial', moment(serviciosTotal.fechas[0]).format("YYYY-MM-DD HH:mm:ss"))
                formData.append('fechaReservaFinal', serviciosTotal.fechas[1] ? moment(serviciosTotal.fechas[1]).format("YYYY-MM-DD HH:mm:ss") : '')
                formData.append('totalEntradas', serviciosTotal.totalEntradas)
                formData.append('totalSeguros', serviciosTotal.totalSeguros)
                formData.append('totalServicioAlimentacion', serviciosTotal.serviciosAlimentacion?.totalServicioAlimentacion || 0)
                formData.append('totalServicioGuia', serviciosTotal.totalServicioGuia)
                formData.append('totalServicioVehiculo', serviciosTotal.totalServicioVehiculo || 0)
                formData.append('totalServiciosGenericos', serviciosTotal.serviciosHospedaje?.totalServiciosGenericos || 0)
                formData.append('servicioVehiculo', encodeURI(JSON.stringify(serviciosTotal.servicioVehiculos || [])))
                formData.append('servicioGuia', encodeURI(JSON.stringify(serviciosTotal.servicioGuia || [])))
                formData.append('serviciosAlimentacion', encodeURI(JSON.stringify(serviciosTotal.serviciosAlimentacion?.serviciosAlimentacion || [])))
                formData.append('serviciosHospedaje', encodeURI(JSON.stringify(serviciosTotal.serviciosHospedaje || [])))
                formData.append('contarServicios', encodeURI(JSON.stringify(serviciosTotal.serviciosAlimentacion?.contarServicios || [])))
                formData.append('entradas', serviciosTotal.entradas)
                formData.append('id_usuario', user?.id)
                formData.append('total', sumaTotal)
                formData.append('id_tipoingreso', serviciosTotal.tipoIngreso.id)
                formData.append('personas', encodeURI(JSON.stringify(serviciosTotal.personas)))
                formData.append('estadoTransaccion', verificarEstado())
                formData.append('metodoPago', sumaTotalPagar == 0 ? "SALDO" : serviciosTotal.mediosPago ? serviciosTotal.mediosPago : 'NUVEI')
                formData.append('abonoTotalUtilizado', abonoTotalUtilizado)
                formData.append('idOperador', user?.operadorId)
                formData.append('restriccionesAlimenticias', serviciosTotal?.serviciosAlimentacion?.restriccionesAlimenticias || '')
                formData.append('saldos', sumaTotalPagar == 0 ? '' : encodeURI(JSON.stringify(saldos)))
                formData.append('senderosDias', senderoMultiple ? encodeURI(JSON.stringify(serviciosTotal.senderosDias)) : '')
                setCargandoBoton(false)
                if (serviciosTotal.id) {
                    formData.append('id_operador', serviciosTotal.operadorId)
                    formData.append('saldoPendiente', saldos.saldoReserva)
                    formData.append('codigoReserva', serviciosTotal.codigoReserva)
                    formData.append('id', serviciosTotal.id)
                    formData.append('modificacionesReserva', serviciosTotal?.estado == 'PENDIENTE' && serviciosTotal?.modificacionesReserva == "true" ? "true" : (serviciosTotal?.estado == 'APROBADO' && saldos.saldoReserva > 0))
                    formData.append('METHOD', 'PUT')

                    const respuesta = await axios.post(baseUrl, formData)

                    //    console.log('respuesta', respuesta.data.id_transaccion)
                    if (sumaTotalPagar <= 0) {
                        navigate(`${RUTAS.ADMIN_RESERVA}/${respuesta.data.id}`, { state: { modificacionesReserva: (serviciosTotal?.estado == 'PENDIENTE' && serviciosTotal?.modificacionesReserva == "true" ? "true" : null) } })
                        return toast.success("Editada con éxito!")

                    }
                    if (parseInt(user.roll) != SUPERADMIN) {
                        PagarReserva(respuesta.data.id, respuesta.data.id_transaccion)
                    } else {
                        navigate(`${RUTAS.ADMIN_RESERVA}/${respuesta.data.id}`, { state: { modificacionesReserva: (serviciosTotal?.estado == 'PENDIENTE' && serviciosTotal?.modificacionesReserva == "true" ? "true" : null) } })
                        setCargandoBoton(false)
                    }

                    //
                } else {
                    formData.append('codigoReserva', moment().format("PMMDDYYYYhhmmss"))

                    if (user && parseInt(user.roll) === TAQUILLA) {
                        formData.append('estadoTransaccion', 'COMPLETADO')
                        formData.append('codigoReserva', moment().format("TMMDDYYYYhhmmss"))
                    }

                    formData.append('METHOD', 'POST')
                    const respuesta = await axios.post(baseUrl, formData)

                    if (sumaTotalPagar == 0) {
                        navigate(`${RUTAS.ADMIN_RESERVA}/${respuesta.data.id}`)
                        return toast.success("Editada con éxito!")
                    }
                    PagarReserva(respuesta.data.id, respuesta.data.id_transaccion)

                    //navigate(`${RUTAS.ADMIN_RESERVA}/${respuesta.data.id}`)
                    //toast.success("Reserva creada con éxito!")
                }
            }

        } catch (error) {
            setCargandoBoton(false)
            console.log(error)
            toast.error("Ocurrio un error,intentelo de nuevo!")
        }
    }


    // Función para generar un token
    const generateToken = (date) => {
        let server_application_code = API_LOGIN_DEV;
        let server_app_key = API_KEY_DEV;
        let unix_timestamp = Math.floor(date.getTime() / 1000);
        let uniq_token_string = server_app_key + unix_timestamp;
        let uniq_token_hash = CryptoJS.SHA256(uniq_token_string).toString();
        let auth_token = btoa(server_application_code + ";" + unix_timestamp + ";" + uniq_token_hash);
        // console.log('unix_timestamp', unix_timestamp);
        // console.log('uniq_token_string', uniq_token_string);
        // console.log('uniq_token_hash', uniq_token_hash);
        // console.log('auth_token', auth_token);

        return auth_token;
    };

    // Función para pagar la reserva
    const PagarReserva = async (IdReserva, IdTransaccion) => {

        let date = new Date();
        const pm_token = generateToken(date); // Reemplaza esto con tu token real

        try {
            const response = await axios({
                url: API_URL_DEV,
                method: 'POST', // Cambia a POST, PUT, etc. según sea necesario
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': pm_token,
                },
                data: {
                    user: {
                        id: IdTransaccion,
                        email: user?.correo || serviciosTotal?.registro?.correo,
                        name: user?.operador || serviciosTotal?.registro?.operador || serviciosTotal?.registro?.nombre,
                        last_name: user?.nombre || serviciosTotal?.registro?.nombre
                    },
                    order: {
                        dev_reference: IdReserva,
                        description: "Reserva",
                        amount: sumaTotalPagar,
                        installments_type: 0,
                        currency: "COP"
                    },
                    configuration: {
                        partial_payment: true,
                        expiration_days: 1,
                        allowed_payment_methods: ["All"],
                        success_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_APROBADO,
                        failure_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_RECHAZADO,
                        pending_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_PENDIENTE,
                        review_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_PENDIENTE
                    }
                },
            });


            let UrlPago = response.data.data.payment.payment_url

            window.location.href = UrlPago;
            // setCargandoBoton(false)
            //console.error('response.data', response.data);


        } catch (error) {
            console.error('Hubo un error con la solicitud:', error);
            setCargandoBoton(false)
        }
    };

    // Función para crear la reserva
    const crearReserva = async () => {
        subirReserva()
        //PagarReserva(IdReserva)
    }

    // Servicio guia

    const totalservicioGuia = serviciosTotal?.servicioGuia ? new Set(
        serviciosTotal?.servicioGuia
            .map(servicio => servicio.guia && servicio.guia.cedula) // Solo mapea si 'guia' existe
            .filter(cedula => cedula !== null && cedula !== undefined) // Filtra valores nulos y no definidos
    ).size
        : 0;
    // Servicio hospedaje
    const totalCantidadServicioGenerico = serviciosTotal?.serviciosHospedaje?.servicioGenericos?.reduce((total, item) => {
        return total + (item.cantidad ? parseInt(item.cantidad) : 0);
    }, 0);
    // Servicio vehiculo
    const totalServicioVehiculo = serviciosTotal?.servicioVehiculos
        ? serviciosTotal.servicioVehiculos.reduce((acc, obj) => {
            // Check if the vehicle has extradata and at least one object with a documento
            const hasDocumento = obj.extradata && obj.extradata.some(data => data.documento);
            // If hasDocumento is true, count the vehicle
            return acc + (hasDocumento ? 1 : 0);
        }, 0)
        : 0;


    return (
        <div>
            <div className='row headReserva'>
                <div className='col-md-1'>
                    <FontAwesomeIcon icon={faTicket} size="3x" style={{ marginTop: 20 }} />
                </div>
                <div className='col-md-8'>
                    <h2><b>{serviciosTotal.tipoIngreso.nombre}</b></h2>
                    {serviciosTotal.codigoReserva && <> <span>Reserva: <b>{serviciosTotal.codigoReserva}</b> - TIPO R: <b>OPERADORA</b></span><br></br></>}
                    <span>Fecha de realización de la Reserva: <b>{arreglarFechaPedido(serviciosTotal.fechaReserva)}</b></span>
                </div>
                <div className='col-md-3'>
                    <b>Fecha de Reservas:</b>
                    <div className='FechaRerserva'>
                        <span>
                            <FontAwesomeIcon icon={faCalendarDays} size="1x" style={{ marginRight: 10 }} />
                            {arreglarFecha(serviciosTotal.fechas[0])}
                        </span>
                    </div>
                    {serviciosTotal.fechas[1] && <div className='FechaRerserva'>
                        <span>
                            <FontAwesomeIcon icon={faCalendarDays} size="1x" style={{ marginRight: 10 }} />
                            {arreglarFecha(serviciosTotal.fechas[1])}
                        </span>
                    </div>}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4 columaReserva'>
                    <h5><FontAwesomeIcon icon={faCreditCardAlt} size="1x" style={{ marginRight: 0 }} /> Datos de pago:</h5>
                    <hr></hr>
                    <span><b>Total Reserva:</b></span>
                    <h3><b><NumericFormat displayType="text" value={sumaTotal} prefix={'$'} thousandSeparator={true} /></b></h3>
                    {sumaTotalPagar ?
                        (<>
                            <span><b>Total Pago Pendiente:</b></span>
                            <h3><b><NumericFormat displayType="text" value={sumaTotalPagar} prefix={'$'} thousandSeparator={true} /></b></h3>
                        </>) : null
                    }
                    {serviciosTotal?.id && sumaTotalPagado ?
                        (<>
                            <span><b>Total Pago Realizado:</b></span>
                            <h3><b><NumericFormat displayType="text" value={sumaTotalPagado} prefix={'$'} thousandSeparator={true} /></b></h3>
                        </>) : null
                    }
                    {serviciosTotal.mediosPago && <>
                        <span><b>Metodo de Pago:</b></span>
                        <h3><b>{serviciosTotal.mediosPago}</b></h3>
                    </>}
                    {abonoTotalUtilizado > 0 && (<>
                        <span><b>Total Abonos Utilizados:</b></span>
                        <h3><b><NumericFormat displayType="text" value={abonoTotalUtilizado} prefix={'$'} thousandSeparator={true} /></b></h3>
                    </>)}
                    {abonos?.saldo_total > 0 && (<>
                        <span><b>Total Abonos Operador:</b></span>
                        <h3><b><NumericFormat displayType="text" value={abonosSaldoTotal} prefix={'$'} thousandSeparator={true} /></b></h3>
                    </>)}

                </div>
                <div className='col-md-4 columaReserva'>
                    {senderoMultiple ?
                        <div>
                            <h5><FontAwesomeIcon icon={faHiking} size="1x" style={{ marginRight: 0 }} /> Senderos:</h5>
                            <hr></hr>
                            {serviciosTotal.senderosDias.map((sendero, index) => (
                                <div>
                                    <b><FontAwesomeIcon icon={faCalendarDays} size="1x" style={{ marginRight: 0 }} /> Dia {index + 1}</b>
                                    <p><b>Fecha: </b>{arreglarFecha(sendero.fecha)}</p>
                                    <p><b>Sendero: </b> {sendero.sendero.nombre}</p>
                                </div>
                            ))}

                            <hr></hr>
                        </div>
                        :
                        null}

                    <h5><FontAwesomeIcon icon={faUsers} size="1x" style={{ marginRight: 0 }} /> Visitantes:</h5>
                    <hr></hr>

                    {serviciosTotal.personas.map((persona, index) =>
                        <div key={persona.id} >
                            <b><FontAwesomeIcon icon={faUser} size="1x" style={{ marginRight: 0 }} /> Visitante {index + 1}</b>
                            <p>{verificarDatosIcon(persona.numeroDocumento)} <b>Documento: </b>{persona.numeroDocumento}</p>
                            <p>{verificarDatosIcon(persona.primerApellido)}<b>Primer Apellido: </b>{persona.primerApellido} </p>
                            <p>{verificarDatosIcon(persona.segundoApellido)} <b>Segundo Apellido: </b>{persona.segundoApellido}</p>
                            <p>{verificarDatosIcon(persona.nombre)} <b>Primer Nombre: </b>{persona.nombre}</p>
                            {persona.segundoNombre && <p>{verificarDatosIcon(persona.segundoNombre)} <b>Segundo Nombre: </b>{persona.segundoNombre}</p>}
                            <p>{verificarDatosIcon(persona.fechaNacimiento)} <b>Edad: </b>{calcularEdad(persona.fechaNacimiento)}</p>
                            {persona?.telefono && <p>{verificarDatosIcon(persona?.telefono)} <b>Teléfono: </b>{persona?.telefono}</p>}
                            {persona?.correo && <p>{verificarDatosIcon(persona?.correo)} <b>Correo: </b>{persona?.correo}</p>}
                            <p>{verificarDatosIcon(persona.tipoDocumento)} <b>Tipo Documento: </b>{persona.tipoDocumento}</p>
                            <p>{verificarDatosIcon(persona.codigoSeguro)} <b>Cod seguro: </b> {persona.codigoSeguro.length > 15 ? <>
                                {persona.codigoSeguro.substring(0, 15)}...
                                <a href={ApiPaths.reservas + persona.codigoSeguro} target="_blank" rel="noopener noreferrer" className='ml-2 rounded-pill btn btn-md m-0 px-3 py-2 z-depth-0 waves-effect btn-primary'><FontAwesomeIcon icon={faFileDownload} size="1x" style={{ marginRight: 0 }} /></a>
                            </> : persona.codigoSeguro} </p>
                            <hr></hr>
                        </div>
                    )}
                    <p><b>Total Entradas: </b><NumericFormat displayType="text" value={serviciosTotal.totalEntradas} prefix={'$'} thousandSeparator={true} /> </p>
                    <p><b>Total Poliza: </b><NumericFormat displayType="text" value={serviciosTotal.totalSeguros} prefix={'$'} thousandSeparator={true} /> </p>
                </div>
                <div className='col-md-4 columaReserva'>
                    <h5><FontAwesomeIcon icon={faTaxi} size="1x" style={{ marginRight: 0 }} /> Servicios:</h5>
                    <hr></hr>
                    {serviciosTotal.servicioVehiculos ?
                        <div>
                            {
                                serviciosTotal.servicioVehiculos ? serviciosTotal.servicioVehiculos.map((item, index) => item.cantidad > 0 &&
                                    <div key={index} className='mb-2'>
                                        <b><FontAwesomeIcon icon={faVanShuttle} size="1x" style={{ marginRight: 0 }} /> Ingreso de {item.nombre}</b>
                                        <p><b>Cantidad: </b>x{item.cantidad} </p>
                                        <p><b>Tarifa: </b><NumericFormat displayType="text" value={item.tarifa} prefix={'$'} thousandSeparator={true} /> </p>
                                        {item.extradata && item.extradata.map((conductor, indx) =>
                                            <div>
                                                {conductor.conductor && <p><b>Conductor ({indx + 1}): </b>{conductor.conductor} </p>}
                                                {conductor.documento && <p><b>Documento: </b>{conductor.documento}</p>}
                                                {conductor.placa && <p><b>Placa: </b>{conductor.placa}</p>}
                                                {conductor.seguro && <p><b>Seguro: </b>{conductor.seguro}</p>}
                                                {conductor.codReserva && <p><b>Codigo Reserva: </b>{conductor.codReserva}</p>}
                                            </div>
                                        )}

                                    </div>
                                )
                                    : null
                            }
                            <p><b>Total:  </b><NumericFormat displayType="text" value={serviciosTotal.totalServicioVehiculo} prefix={'$'} thousandSeparator={true} /> </p>
                            <hr></hr>
                        </div>
                        : null
                    }

                    <div>
                        <b><FontAwesomeIcon icon={faBinoculars} size="1x" style={{ marginRight: 0 }} /> Servicios Uton</b>
                        <p><b>Cantidad: </b>x{serviciosTotal.entradas} </p>
                        <p><b>Total: </b><NumericFormat displayType="text" value={serviciosTotal.totalServicioGuia} prefix={'$'} thousandSeparator={true} /> </p>
                        {serviciosTotal.servicioGuia && serviciosTotal.servicioGuia.map((item, index) =>
                            <div key={index}>
                                <p className='mt-2'><FontAwesomeIcon icon={faUser} size="1x" style={{ marginRight: 0 }} /><b> Guia ({index + 1}): </b></p>
                                <p><b>Nombre: </b> {item.guia?.nombre} </p>
                                {item?.guia?.codReserva ?
                                    <p><b>Codigo de reserva: </b> {item.guia?.codReserva} </p>
                                    :
                                    <>
                                        <p><b>Cedula: </b> {item.guia?.cedula} </p>
                                        <p ><b>Telefono: </b> {item.guia?.telefono} </p>
                                    </>}
                            </div>
                        )}
                        <hr></hr>
                    </div>


                    {serviciosTotal.serviciosHospedaje && serviciosTotal.serviciosHospedaje.servicioGenericos.length > 0 ?
                        <div>
                            <b><FontAwesomeIcon icon={faHouse} size="1x" style={{ marginRight: 0 }} /> Hospedaje</b>
                            {/* <p><b>Cantidad: </b>x{totalCantidadServicioGenerico + totalServicioVehiculo + totalservicioGuia} </p> */}
                            <p><b>Cantidad: </b>x{totalCantidadServicioGenerico} </p>
                            <p className='mb-2'><b>Total: </b><NumericFormat displayType="text" value={serviciosTotal.serviciosHospedaje.totalServiciosGenericos} prefix={'$'} thousandSeparator={true} /> </p>
                            {serviciosTotal.serviciosHospedaje.servicioGenericos.map((item, index) => item.cantidad > 0 &&
                                <div className='mb-2' key={index}>
                                    <p><b>{item.nombre}</b></p>
                                    <p><b>Cantidad: </b>{item.cantidad || 0}</p>
                                </div>
                            )}
                            {/* <p style={{ fontSize: '14px' }}><b>Nota: </b> El Staff tiene {totalservicioGuia + totalServicioVehiculo} cupos de habitaciones gratuitas.</p> */}
                            <hr></hr>
                        </div>
                        : null
                    }
                </div>
            </div>
            {
                serviciosTotal.serviciosAlimentacion && <div className='row'>
                    <div className='col-md-12 '>
                        <ServicioAlimentacion titulo={'Servicio de alimentacion'} serviciosTotal={serviciosTotal} servicios={serviciosAlimentacion} readOnly actualizarServiciosGeneralesTotal={cambiarServiciosTotal} detalle={true} />
                    </div>
                </div>
            }


            {
                pagar && <div className='row'>
                    <div className="col d-flex justify-content-end">
                        <button type='button' onClick={retrocederCambioVista} className="btn boton_estandar">Atrás</button>
                        <button type='button' disabled={cargandoBoton} onClick={crearReserva} className={`btn boton_estandar ${cargandoBoton && "text-light"}`}>
                            {cargandoBoton && (<div className="mr-2 spinner-border text-light spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span></div>)}

                            {serviciosTotal.id ? 'Editar Ahora' : 'Pagar Ahora'}</button>
                    </div>
                </div>
            }

        </div >
    )
}
