import React from 'react'
import * as RUTAS from '../../constantes/rutas';
import Logo from '../../paginas/img/logo.png'
import { NavLink } from 'react-router-dom';
import '../../paginas/estilos/estilos.css'
import ImagenLogo from '../../paginas/img/logoTecnosoluciones.png';
import { Image } from 'react-bootstrap';

const FormularioLogin = ({ onChange, form, onSubmit, errorForm, sede }) => {

	return (
		<div className="Login">
			<a href="https://www.operacionnevados.co/" target="_blank" rel="noopener noreferrer"> 
				<div className="align-self-center text-center container">
					<img  src="https://reservasnevados.com/picoyplaca.jpg" style={{width: 500}}/>
				</div>
			</a>

			<div className="card align-self-center text-center container">
				
				<div className="card-body px-lg-5 pt-0">

					<img className="Logo_login" src={Logo} />

					<form className="text-center" onSubmit={onSubmit}>

						<div className="md-form">
							<input
								type="text"
								className="form-control"
								name="usuario"
								placeholder="Usuario"
								onChange={onChange}
								value={form.usuario}
							/>
						</div>

						<div className="md-form">
							<input
								type="password"
								className="form-control"
								name="clave"
								placeholder="Contraseña"
								onChange={onChange}
								value={form.clave}
							/>
						</div>
						<p className={errorForm !== '' ? 'animated bounceInRight' : ''}>{errorForm}</p>
						<button type="submit" className="btn boton_estandar btn-rounded btn-block my-4 btn-lg">Ingresar</button>
						<span>Sede: {sede}</span>
					</form>
					<div className='login-links-alternos'>
						<NavLink to={RUTAS.OPERADORES} >
							Registrarse como Operadora
						</NavLink>
						<NavLink to={RUTAS.GUIAS} >
							Registrarse como Guia
						</NavLink>

						<NavLink to={RUTAS.RECUPERAR_CONTRASENA} >
							Recuperar contraseña
						</NavLink>

						<NavLink className="btn boton_estandar btn-rounded btn-block my-4 btn-lg" to={RUTAS.RESERVAS} >
							Reserva Persona Natural
						</NavLink>

					</div>
					<div className='login-links-alternos-logo'>
						<p>Desarrollado por:</p>
						<Image
							src={ImagenLogo}
						/>

					</div>

				</div>
			</div>
		</div>
	)

}

export default FormularioLogin
